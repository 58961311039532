import {
  doc,
  collection,
  getFirestore,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  writeBatch,
  query,
  where
} from '@firebase/firestore'
import { getAuth } from '@firebase/auth'
import { getApp } from '@firebase/app'

const appImpl = getApp()
const appAuth = getAuth(appImpl)
const firestore = getFirestore(appImpl)

const formatDocSnap = (snap) => {
  const data = snap.data()
  if (!data) return null
  if (data.date) data.date = data.date.toDate()
  return { id: snap.id, ...data }
}

const actions = {
  // ===========================================================================

  openDb: () => {
    //toto
  },

  // ===========================================================================

  async dbAdd(context, { storeName, obj }) {
    const user = appAuth.currentUser.uid
    const { id, ...props } = obj
    const path = storeName + '/' + id
    const document = doc(firestore, path)
    await setDoc(document, { user, ...props })
  },

  // ===========================================================================

  // dbGetAll: (context, { storeName }) => {},

  // ===========================================================================

  async dbGet(context, { storeName, key }) {
    const path = storeName + '/' + key
    const document = doc(firestore, path)
    const snap = await getDoc(document)
    return formatDocSnap(snap)
  },

  // ===========================================================================

  async dbIndex(context, { storeName, indexName, indexValue }) {
    const currentUser = appAuth.currentUser
    if (!currentUser) return []
    const path = storeName
    const col = collection(firestore, path)
    const q = query(
      col,
      where(indexName, '==', indexValue),
      where('user', '==', currentUser.uid)
    )
    const snap = await getDocs(q)
    return snap.docs.map(formatDocSnap)
  },

  // ===========================================================================

  async dbSet(context, { storeName, key, property, value }) {
    const path = storeName + '/' + key
    const document = doc(firestore, path)
    await updateDoc(document, { [property]: value })
  },

  // ===========================================================================

  async dbDelRecurse({ dispatch }, { key }) {
    const allItems = [key]
    const batch = writeBatch(firestore)
    const recurse = async (key) => {
      let items = await dispatch('dbIndex', {
        storeName: 'drive',
        indexName: 'parent',
        indexValue: key
      })
      for await (let item of items) {
        allItems.push(item.id)
        await recurse(item.id)
      }
      batch.delete(doc(firestore, 'drive/' + key))
      batch.delete(doc(firestore, 'scores/' + key))
    }
    await recurse(key)
    batch.commit()
  }

  // ===========================================================================

  // dbDel(context, { storeName, key }) {}

  // ===========================================================================

  // dbExport: (context, { indent }) => {},

  // ===========================================================================

  // dbImport: (context, { json }) => {},
  // ===========================================================================

  /**
   * Clear the database
   */

  // dbClear: () => {}
}

export default {
  namespaced: true,
  actions
}
