import { generateID } from '../utils/store-utils'
import { fromChordPro } from '../utils/import-utils'
import { File, Folder, Metadata } from '../utils/class-utils'

import { until } from '../../../jelly-sheet/src/utils/tools-utils'

import router from '../router'
import deburr from 'lodash/deburr'

// =============================================================================
// =============================================================================
// =============================================================================

const state = {
  current: null,
  currentIds: null,
  breadcrumbs: null,
  selectedId: null,
  draggedItem: null,
  updating: false,
  settings: {
    sort: { on: 'name' }
  }
}

const getters = {
  selected: (state) => {
    const items = state.current?.items
    return items?.find((item) => item.id === state.selectedId) || {}
  },
  current(state) {
    return state.current || { items: [] }
  },
  breadcrumbs(state) {
    return state.breadcrumbs
  },
  driveUpdating(state) {
    return state.updating
  },
  //
  // Settings
  //
  sort: (state) => state.settings.sort
}

const mutations = {
  current(state, folder) {
    state.current = folder
  },
  setCurrentIds(state, value) {
    state.currentIds = value
  },
  setUpdating(state, value) {
    state.updating = value
  },
  breadcrumbs(state, items) {
    state.breadcrumbs = items
  },
  setDraggedItem(state, value) {
    state.draggedItem = value
  },
  setName(state, { id, name }) {
    const items = []
    if (state.current) items.push(state.current)
    if (state.current?.items) items.push(...state.current.items)
    if (state.breadcrumbs) items.push(...state.breadcrumbs)
    items.forEach((item) => {
      if (item.id === id) {
        item.name = name
      }
    })
  },
  setMeta(state, { id, meta }) {
    const items = []
    if (state.current) items.push(state.current)
    if (state.current?.items) items.push(...state.current.items)
    items.forEach((item) => {
      if (item.id === id) {
        item.meta = new Metadata(meta)
      }
    })
  },
  //
  // Settings
  //
  sort: (state, value) => (state.settings.sort = value)
}

const actions = {
  //
  // ===========================================================================

  setSettings({ commit }, { name, value }) {
    commit(name, value)
  },

  notify({ dispatch }, type) {
    const details = { store: 'drive', type, current: state.current }
    //
    if (type === 'open-score') {
      dispatch('score/on', details, { root: true })
    }
  },

  db({ dispatch }, payload) {
    const { action, ...args } = payload
    const driveId = router.currentRoute.params.driveId
    const storeName = driveId === 'remote' ? 'driveRemote' : 'driveLocal'
    return dispatch(storeName + '/' + action, args, { root: true })
  },

  // ===========================================================================

  /**
   * listener from other stores
   */

  on({ dispatch }, { store }) {
    if (store == 'auth') {
      dispatch('update')
    }
  },

  // ===========================================================================

  async update({ state, commit, dispatch }) {
    //
    // Check if updating, in this case we wait the end of the current update.
    // Operation is aborted
    //
    if (state.updating) {
      await until(() => !state.updating)
      return
    }
    //
    // Let's start
    //
    const driveId = router.currentRoute.params.driveId
    const folderId = router.currentRoute.params.folderId
    const scoreId = router.currentRoute.params.scoreId
    commit('setUpdating', true)
    //
    // Get the current item (folder or file)
    //
    let current
    if (!driveId) {
      //
      // No drive id, this is the root of the drive. We only have two static
      // folders for local (IndexedDB) or remote (firebase)
      //
      current = new Folder({
        id: 'root',
        isRoot: true,
        items: [
          new Folder({ id: 'local', isLocalRoot: true, path: 'local' }),
          new Folder({ id: 'remote', isRemoteRoot: true, path: 'remote' })
        ]
      })
    } else if (!scoreId) {
      let currentId = folderId || driveId
      if (currentId !== driveId) {
        //
        // Current is a folder
        //
        const currentItem = await dispatch('db', {
          action: 'dbGet',
          storeName: 'drive',
          key: currentId
        })
        current = new Folder(currentItem)
        current.path = driveId + '/' + current.id
      } else {
        //
        // Current is a root folder of a drive (local or remote)
        //
        current = new Folder({ id: driveId, isLocalRoot: true })
      }
      current.canHaveChildren = true
      //
      // Get the children
      //
      let items = await dispatch('db', {
        action: 'dbIndex',
        storeName: 'drive',
        indexName: 'parent',
        indexValue: currentId
      })
      current.items = items.map((item) => {
        const isFolder = item.type === 'folder'
        item.sortName = deburr(item.name.toLowerCase()).replace(/\s+/g, '')
        item.canBeDeleted = true
        item.canBeRenamed = true
        item.path = driveId + '/' + item.id
        if (typeof item.date === 'string') {
          item.date = new Date(item.date)
        }
        if (isFolder) {
          return new Folder(item)
        } else {
          return new File(item)
        }
      })
      //
      // Sort the children
      //
      const getWeight = (a) => (a.isFolder ? 1 : 0)
      const sort = state.settings.sort
      current.items.sort((a, b) => {
        const wa = getWeight(a)
        const wb = getWeight(b)
        if (wa !== wb) {
          return wb - wa
        }
        switch (sort.on) {
          case 'name': {
            return a.sortName.localeCompare(b.sortName)
          }
          case 'date': {
            const diff = a.date.getTime() - b.date.getTime() > 0 ? 1 : -1
            return sort.desc ? -diff : diff
          }
        }
      })
    } else {
      //
      // Current is a file. We are in score view
      //
      const currentItem = await dispatch('db', {
        action: 'dbGet',
        storeName: 'drive',
        key: scoreId
      })
      current = new File(currentItem)
    }

    commit('current', current)
    if (current) await dispatch('updateBreadcrumbs')
    commit('setUpdating', false)
    console.log('DRIVE CURRENT', current)
    return current
  },

  // ===========================================================================

  async updateBreadcrumbs({ state, commit, dispatch }) {
    const current = state.current
    const driveId = router.currentRoute.params.driveId
    const breadcrumbs = []
    //
    // Get the ancestors
    //
    breadcrumbs.push(current)

    let pointer = current
    while (pointer.parent) {
      let ancestor
      if (pointer.parent === driveId) {
        //
        // Local root ancestor
        //
        ancestor = new Folder({
          id: driveId,
          isRoot: true
        })
      } else {
        //
        // Non root ancestor
        //
        // ancestor = await dbGet('drive', pointer.parent)
        ancestor = await dispatch('db', {
          action: 'dbGet',
          storeName: 'drive',
          key: pointer.parent
        })
        ancestor = new Folder(ancestor)
      }
      pointer = ancestor
      breadcrumbs.push(ancestor)
    }
    commit('breadcrumbs', breadcrumbs.reverse())
  },

  // ===========================================================================

  async openFolder({ dispatch }) {
    const current = await dispatch('update')
    return current
  },

  // ===========================================================================

  async openScore({ dispatch }) {
    await dispatch('update')
    await dispatch('notify', 'open-score')
  },

  // ===========================================================================

  async add({ dispatch }, { name, type, noUpdate }) {
    const driveId = router.currentRoute.params.driveId
    const folderId = router.currentRoute.params.folderId
    const obj = {
      name,
      type,
      date: new Date()
    }
    obj.id = generateID()
    obj.parent = folderId || driveId
    await dispatch('db', {
      action: 'dbAdd',
      storeName: 'drive',
      obj
    })
    if (!noUpdate) {
      await dispatch('update', { force: true })
    }
    return obj
  },

  // ===========================================================================

  async addFromChordPro({ dispatch }, { name, raw }) {
    const draft = fromChordPro(raw)
    const { id } = await dispatch('add', { name, type: 'file', noUpdate: true })
    await dispatch('score/createScore', { id, draft }, { root: true })
    await dispatch('update')
  },

  // ===========================================================================

  /**
   * Set name of the file.
   *
   * This action is called by RenameDialog (score or drive vue)
   */

  async driveRename({ dispatch, commit }, { id, name }) {
    await dispatch('db', {
      action: 'dbSet',
      storeName: 'drive',
      key: id,
      property: 'name',
      value: name
    })
    commit('setName', { id, name })
  },

  // ===========================================================================

  /**
   * Set meta of the file.
   *
   * This action is called by MetadataDialog (score or drive vue)
   */

  async driveSetMeta({ dispatch, commit }, { id, meta }) {
    await dispatch('db', {
      action: 'dbSet',
      storeName: 'drive',
      key: id,
      property: 'meta',
      value: meta
    })
    commit('setMeta', { id, meta })
  },

  // ===========================================================================

  async move({ dispatch }, { sourceId, destinationId }) {
    if (sourceId === destinationId) {
      throw new Error('drag-self-drop')
    }
    if (!sourceId || !destinationId) {
      throw new Error('drag-no-destination')
    }
    // await dbSet('drive', sourceId, 'parent', destinationId)
    await dispatch('db', {
      action: 'dbSet',
      storeName: 'drive',
      key: sourceId,
      property: 'parent',
      value: destinationId
    })
    await dispatch('update')
  },

  // ===========================================================================

  async sort({ dispatch }, sort) {
    await dispatch('app/setSettings', ['drive/sort', sort], { root: true })
    await dispatch('update')
  },

  // ===========================================================================

  async del({ dispatch }, item) {
    // await dbDelRecurse('drive', item.id)
    await dispatch('db', {
      action: 'dbDelRecurse',
      storeName: 'drive',
      key: item.id
    })
    await dispatch('update')
  },

  // ===========================================================================

  async toJson({ dispatch }, indent) {
    // const json = await dbExport(indent)
    const json = await dispatch('db', {
      action: 'dbExport',
      indent
    })
    await dispatch('update')
    return json
  },

  // ===========================================================================

  async fromJson({ dispatch }, json) {
    // await dbClear()
    await dispatch('db', { action: 'dbClear' })
    // await dbImport(json)
    await dispatch('db', { action: 'dbImport', json })
    await dispatch('update')
  },

  // ===========================================================================

  async clear({ dispatch }) {
    // await dbClear()
    await dispatch('db', { action: 'dbClear' })
    await dispatch('update')
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
