import { openPeer, openConnection, STATUS } from '../utils/peer-utils'

// Timeout for ping
const PING_TIMEOUT = 5000

let _peer, _connection

// =============================================================================

const state = {
  error: null,
  status: STATUS.off,
  remoteId: '',
  //
  // Settings
  //
  settings: {
    peerId: null,
    peerLabel: ''
  }
}

// =============================================================================

const getters = {
  peerError: (state) => state.error,
  peerStatus: (state) => state.status,
  peerRemoteId: (state) => state.remoteId,
  //
  // Settings
  //
  peerId: (state) => state.settings.peerId,
  peerLabel: (state) => state.settings.peerLabel
}

// =============================================================================

const mutations = {
  setStatus(state, value) {
    state.status = value
  },
  setError(state, value) {
    if (!value) {
      state.error = null
      state.status = STATUS.off
    } else {
      state.error = {
        message: value.message,
        type: value.type
      }
      state.status = STATUS.error
      if (_connection) {
        _connection.close()
        _connection = null
      }
      if (_peer && !_peer.disconnected) {
        _peer.disconnect()
      }
    }
  },
  setRemoteId(state, value) {
    state.remotePeerId = value
  },
  //
  // Settings
  //
  peerId: (state, value) => (state.settings.peerId = value),
  peerLabel: (state, value) => (state.settings.peerLabel = value)
}

const actions = {
  setSettings({ commit }, { name, value }) {
    commit(name, value)
  },

  // ===========================================================================

  async peerConnect({ state, commit, dispatch }, remoteId) {
    //
    // Check status
    //
    if (state.status === STATUS.connecting) {
      console.warn('WTF ! Peer is already trying to connect')
      return
    }
    if (state.status === STATUS.on) {
      console.warn('WTF ! Peer is already connected')
      return
    }
    //
    // Check params
    //
    if (!remoteId) {
      commit('setError', {
        type: 'missing-remote-id',
        message: 'The id of the broadcast is missing'
      })
      return
    }
    //
    // Start peer opening
    //
    commit('setStatus', STATUS.connecting)
    await openPeer(_peer, state.settings.peerId)
      .then(({ peer, peerId }) => {
        if (_peer != peer) {
          _peer = peer
          console.log('PEER CREATED ', peerId)
        } else {
          console.log('PEER RECONNECTED ', peerId)
        }
        if (peerId != state.settings.peerId) {
          dispatch('app/setSettings', ['peer/peerId', peerId], {
            root: true
          })
        }
      })
      .catch((err) => {
        commit('setError', err)
      })
    if (state.status === STATUS.error) return
    //
    // Opening the connection to the remote peer
    //
    await openConnection(_peer, remoteId, state.settings.peerLabel)
      .then((connection) => {
        _connection = connection
      })
      .catch((err) => {
        commit('setError', err)
      })
    if (state.status === STATUS.error) return
    let pingReceived = false
    let promiseFinalized = false
    return new Promise((resolve, reject) => {
      //
      // Add data callback to then connection
      //
      _connection.on('data', function (dataObject) {
        const { type, ...data } = dataObject
        switch (type) {
          case 'init':
            commit('setStatus', STATUS.on)
            commit('setRemoteId', remoteId)
            promiseFinalized = true
            console.log('CONNECTED TO REMOTE', data)
            pingReceived = true
            dispatch('score/openFromPeer', data, { root: true })
            resolve()
            break
          case 'ping':
            pingReceived = true
            break
          case 'score':
            console.log('REMOTE SCORE UPDATE', data)
            dispatch('score/openFromPeer', data, { root: true })
            break
          case 'play':
            console.log('REMOTE PROGRESS UPDATE', data)
            dispatch('player/updateFromPeer', data, { root: true })
            break
          case 'close':
            commit('setError', {
              type: 'server-close',
              message: 'The channel has been closed.'
            })
            break
          case 'disconnect':
            commit('setError', {
              type: 'server-disconnect',
              message: 'You have been disconnected from the channel.'
            })
            break
          default:
            console.log('RECEIVED FROM SERVER', data)
        }
      })
      //
      // Start loop to ping
      //
      const loop = (firstLoop) => {
        if (!pingReceived && !firstLoop) {
          commit('setError', {
            type: 'ping-timeout',
            message: "Remote peer doesn't response"
          })
          if (!promiseFinalized) reject()
        } else {
          if (state.status === STATUS.on) {
            _connection.send({ type: 'ping' })
            setTimeout(loop, PING_TIMEOUT)
            pingReceived = false
          } else if (state.status === STATUS.connecting) {
            _connection.send({ type: 'init' })
            setTimeout(loop, PING_TIMEOUT)
            pingReceived = false
          }
        }
      }
      loop(true)
    })
  },

  // ---------------------------------------------------------------------------

  peerClear({ commit }) {
    commit('setError')
  },

  peerLeave({ commit }) {
    if (!_connection) {
      console.warn('WTF ! No opened connection to close')
    }
    // _connection.close()
    _connection.send({ type: 'leave' })
    _connection = null
    _peer.disconnect()
    console.log('PEER LEAVE')
    commit('setStatus', STATUS.off)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
