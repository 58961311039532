<template>
  <div class="list-diagram" v-resize="debounceUpdate">
    <div class="position" v-show="type === 'guitar'">
      <app-icon value="guitar-head-horizontal" />
      <v-slider
        hide-details
        v-model="diagramIndex"
        :max="diagramCount - 1 || 0"
        :min="0"
        tick-size="4"
        ticks="always"
      />
      <app-icon value="guitar-body-horizontal" />
    </div>
    <div class="progress" :style="`transform:scaleX(${chordProgress})`" />
    <div :class="diagramsClass" ref="diagrams" v-show="rendered">
      <div
        :class="getLabelClass(diagram, currentDiagram)"
        v-for="diagram in diagrams"
        :key="diagram.key"
      >
        <div class="chord" :ref="diagram.id"></div>
        <canvas :ref="diagram.id" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

import AppIcon from '../misc/AppIcon.vue'

import debounce from 'lodash/debounce'

import { until } from '../../../../jelly-sheet/src/utils/tools-utils'

import guitarRenderer from '../../../../jelly-chord/src/diagrams/guitar-diagram'
import pianoRenderer from '../../../../jelly-chord/src/diagrams/piano-diagram'
import Chord from '../../../../jelly-chord/src/model/Chord'

export default {
  name: 'ListDiagram',
  components: {
    AppIcon
  },
  props: {
    type: String,
    size: String
  },

  data: () => ({
    fromTime: 0,
    toTime: 0,
    rawTo: null,
    rawFrom: null,
    diagramIndex: 0,
    diagramCount: 4,
    rendered: false
  }),

  computed: {
    ...mapGetters('info', ['diagram']),
    ...mapGetters('app', ['settings']),
    ...mapGetters('score', ['report', 'scoreSettings']),
    ...mapGetters('player', ['headEvent', 'progress', 'tempoRatio']),

    chordProgress() {
      const fromToNext = this.toTime - this.fromTime
      const fromToHead = this.progress - this.fromTime
      return fromToNext && fromToHead ? fromToHead / fromToNext : 0
    },

    diagrams() {
      if (!this.report) return []
      const r = []
      const events = this.report.events()
      const rowHeight = this.type === 'guitar' ? 240 : 200
      events.forEach((event, index) => {
        const raw = event.chord
        if (raw && raw != '-') {
          r.push({
            raw: event.chord,
            key: index,
            id: `diagram-${index}`,
            time: Math.round(event.position.time / this.tempoRatio),
            rowHeigh: rowHeight
          })
        }
      })
      return r
    },

    diagramsClass() {
      const classes = ['diagrams']
      if (this.report?.hasMetric) classes.push('has-metric')
      return classes
    },

    currentDiagram() {
      if (!this.headEvent) return null
      const currentTime = Math.round(
        this.headEvent.position.time / this.tempoRatio
      )
      return this.diagrams.find((d, i) => {
        const next = this.diagrams[i + 1]
        return (
          !next || (d.time >= currentTime && !next) || currentTime < next.time
        )
      })
    }
  },
  watch: {
    diagrams() {
      this.debounceUpdate()
    },
    'scoreSettings.transpose'() {
      this.debounceUpdate()
    },
    diagramIndex(value) {
      this.setSettings(['info/diagram', value])
      this.debounceUpdate()
    },
    'settings.info.diagram': {
      handler(value) {
        this.diagramIndex = value
      },
      immediate: true
    },
    currentDiagram() {
      this.updateScroll()
    },
    size() {
      this.debounceUpdate()
    }
  },
  created() {
    this.debounceUpdate = debounce(() => {
      this.rendered = false
      this.update()
    }, 10)
    this._cache = new Map()
  },
  mounted() {
    this.debounceUpdate()
  },
  methods: {
    ...mapActions('app', ['setSettings']),

    async update() {
      if (this.diagrams?.length) {
        await until(() => this.diagrams[0].id in this.$refs)
      }
      this.updateCanvas()
    },

    updateCanvas() {
      this.diagrams.forEach((diagram) => {
        const type = this.type
        const transpose = this.scoreSettings.transpose
        const diagramIndex = this.diagramIndex
        const cacheId = `${diagram.raw}_${type}_${transpose}_${diagramIndex}_${this.size}`
        let cache = this._cache.get(cacheId)
        const elts = this.$refs[diagram.id]
        const canvas = elts[1]
        const labelEl = elts[0]
        if (!cache) {
          const cacheCanvas = document.createElement('canvas')
          const renderer = type === 'guitar' ? guitarRenderer : pianoRenderer
          const chord = new Chord(diagram.raw, transpose)
          renderer(cacheCanvas, chord, diagramIndex, this.size)
          cache = { chord, canvas: cacheCanvas }
          this._cache.set(cacheId, cache)
        }
        canvas.width = cache.canvas.width
        canvas.height = cache.canvas.height
        canvas.style.width = `${cache.canvas.width}px`
        canvas.style.height = `${cache.canvas.height}px`
        canvas.getContext('2d').drawImage(cache.canvas, 0, 0)
        labelEl.textContent = cache.chord.current.raw
        this.rendered = true
        this.$nextTick(this.updateScroll)
      })
    },

    updateScroll() {
      const scroller = this.$refs.diagrams
      const diagramId = this.currentDiagram?.id
      const elt = diagramId in this.$refs && this.$refs[diagramId][1]
      if (!scroller || !elt) return

      const scrollerBound = scroller.getBoundingClientRect()
      const eltBound = elt.getBoundingClientRect()
      const actualTop = eltBound.top - scrollerBound.top
      const expectedTop = scrollerBound.height / 3
      const offset = actualTop - expectedTop
      scroller.scrollBy({
        top: offset,
        behavior: 'smooth'
      })
    },

    getLabelClass(diagram, currentDiagram) {
      if (!currentDiagram) return ''
      const r = ['diagram']
      if (diagram.id === currentDiagram.id) r.push('current')
      return r
    }
  }
}
</script>

<style scoped>
.list-diagram {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.position {
  padding: 0 var(--app-gutter-md);
  border-bottom: solid 1px var(--app-color-line);
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-input /deep/ .v-slider__tick--filled {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.progress {
  min-height: 2px;
  background: var(--v-accent-base);
  width: 100%;
  transform-origin: left;
  padding: 0;
  flex: 0;
}

.diagrams {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.diagram {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px var(--app-color-line);
  align-items: center;
  justify-content: center;
  padding: var(--app-gutter-md) 0;
}

.chord {
  font-family: Palanquin;
  background: #00000011;
  border-radius: 2px;
  font-size: 18px;
  padding: 0 8px;
  padding-bottom: 2px;
  margin-bottom: 8px;
}
.has-metric .current .chord {
  background: #e981004d;
}
</style>
