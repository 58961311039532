import { manifest } from '../../../jelly-sheet/src/settings/SheetSettings'

const CHARS = {}
manifest.forEach((m) => {
  CHARS[m.name] = m.symbol
})

export const fromChordPro = (raw) => {
  raw = raw.replace(/\r/g, '\n')
  raw = raw.replace(/\n\s+\n/g, '\n')
  raw = raw.replace(/\n+/g, '\n')

  const lines = raw.split('\n')
  const partReg = /^\s*\[(.+)\]\s*$/
  const chordReg = /([ABCDEFG])([#b])?(sus|maj|dim|aug|[mM°])?([\d])?(\(?[#b]?[\d]\)?)?(\/[ABCDEFG][#b]?)?/g
  const pool = []
  const result = []
  lines.forEach((line) => {
    //
    // The commit allow to convert pool content into result lines
    //
    const commit = () => {
      if (!pool.length) {
        return
      } else if (pool.length == 1) {
        //
        // Single line
        //
        const words = pool[0].words.map((w) => w.text)
        const lineType = pool[0].isChord ? CHARS.chord : CHARS.lyric
        result.push(`${lineType} | ${words.join(' ')}\n\n`)
      } else {
        //
        // Two lines (chord + lyric)
        //
        const lyrics = []
        const chords = []
        let secure = 0
        const chordLine = pool[0]
        const lyricLine = pool[1]

        while (chordLine.words.length || lyricLine.words.length) {
          const nextChord = chordLine.words[0] || { pos: Infinity }
          const nextLyric = lyricLine.words[0] || { pos: Infinity }

          if (nextLyric.pos <= nextChord.pos) {
            //
            // When a lyric is found...
            //
            if (nextLyric.pos + nextLyric.text.length < nextChord.pos) {
              //
              // ... with no chord
              //
              chords.push('-')
              lyrics.push(lyricLine.words[0].text)
              lyricLine.words.shift()
            } else {
              //
              // ... with chord
              //
              chords.push(chordLine.words[0].text)
              lyrics.push(lyricLine.words[0].text)
              chordLine.words.shift()
              lyricLine.words.shift()
            }
          } else {
            //
            // When a chord is found with no lyric
            //
            chords.push(chordLine.words[0].text)
            lyrics.push('-')
            chordLine.words.shift()
          }
          if (secure++ > 1000) throw new Error('secure-error')
        }

        const sizes = []
        const length = lyrics.length
        for (var i = 0; i < length; i++) {
          sizes[i] = Math.max(lyrics[i].length, chords[i].length)
        }
        for (i = 0; i < length; i++) {
          lyrics[i] = lyrics[i].padEnd(sizes[i])
          chords[i] = chords[i].padEnd(sizes[i])
        }

        result.push(`${CHARS.chord} | ${chords.join(' ')}\n`)
        result.push(`${CHARS.lyric} | ${lyrics.join(' ')}\n\n`)
      }
      pool.length = 0
    }
    //
    // Empty line
    //
    if (!line) return
    //
    // Part Line
    //
    const matchPart = line.match(partReg)
    if (matchPart) {
      commit()
      result.push(`${CHARS.part} > ${matchPart[1]}\n\n`)
      return
    }
    //
    // Split line into words list with position
    //
    let words = []
    let currentWord
    const charCount = line.length
    for (var i = 0; i < charCount; i++) {
      const char = line.charAt(i)
      if (char !== ' ') {
        if (!currentWord) {
          currentWord = { text: '', pos: i }
        }
        currentWord.text += char
      }
      if ((char === ' ' || i == charCount - 1) && currentWord) {
        words.push(currentWord)
        currentWord = null
      }
    }
    //
    // Check if line is chord
    //
    const isChord = words.every((w) => w.text.match(chordReg))
    //
    // If the pool is not empty we need to commit because we'll add a new line
    // and this this the max line in the pool
    //
    // If the current line is lyric and the pool has a chord line the commit
    // will be done after the push. In others cases, the line in the pool is
    // a single one (if more than on line of the same type follow each other)
    //
    let commitBefore, commitAfter
    if (pool.length) {
      commitAfter = pool[0].isChord && !isChord
      commitBefore = !commitAfter
    }
    if (commitBefore) commit()
    pool.push({ words, isChord })
    if (commitAfter) commit()
  })
  return result.join('')
}
