import { mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import { timeout } from '../../../jelly-sheet/src/utils/tools-utils'

export default {
  data: () => ({
    isSearching: false,
    isEmpty: true,
    hasResult: false
  }),
  computed: {
    searchResult() {
      return this.$store.getters['search/searchResult'](this.type)
    }
  },
  created() {
    this.search = debounce(async (searchValue) => {
      if (!searchValue) {
        this.isSearching = false
        this.isEmpty = true
        this.hasResult = false
        return
      }
      const start = new Date()
      this.isSearching = true
      await this.debouncedSearch({ query: searchValue, type: this.type })
      const end = new Date()
      const delay = Math.max(0, 500 - end.getTime() + start.getTime())
      await timeout(delay)
      this.hasResult = this.searchResult != null
      this.isEmpty = !this.searchResult?.length
      this.isSearching = false
    }, 50)
  },
  methods: {
    ...mapActions('search', { debouncedSearch: 'search' })
  }
}
