export const Base = class {
  constructor(args) {
    for (const p in args) {
      this[p] = args[p]
    }
  }
}

export const Folder = class extends Base {
  constructor(args) {
    super(args)
    this.isFolder = true
  }
}
export const File = class extends Base {
  constructor(args) {
    super(args)
    this.meta = new Metadata(this.meta)
    this.isFile = true
  }
}
export const Score = class extends Base {
  static fromPeer(peer) {
    const score = new Score({
      id: peer.id,
      name: peer.name,
      draft: peer.content,
      raw: peer.content,
      fromPeer: true
    })
    score.settings.transpose = peer.settings.transpose
    score.settings.tempo = peer.settings.tempo
    return score
  }
  constructor(args) {
    super(args)
    this.content = this.draft = ''
    this.settings = {
      tempo: 1,
      transpose: 0,
      layout: 'wrap',
      spacing: 'default'
    }
    for (const p in args) {
      this[p] = args[p]
    }
  }
  toPeer() {
    return {
      id: this.id,
      content: this.content || this.draft,
      name: this.name,
      settings: {
        tempo: this.settings.tempo,
        transpose: this.settings.transpose
      }
    }
  }
}

export const Metadata = class {
  constructor(raw) {
    this.raw = raw
    if (raw) {
      Object.keys(raw).forEach((metaName) => {
        if (typeof raw[metaName] !== 'string') return
        this[metaName] = raw[metaName].split(',').map((split) => split.trim())
      })
    }
  }
  getRawValue(name) {
    const value = this[name]
    return (value && value.join(',')) || ''
  }
}
