<template>
  <common-dialog
    ref="dialog"
    :trigger="proceed"
    :disabled="!email || !password"
    title="Login"
    width="350"
  >
    <v-form>
      <v-text-field
        label="Email"
        v-model="email"
        placeholder=" "
        persistent-placeholder
        hide-details
      />
      <v-text-field
        label="Password"
        v-model="password"
        type="password"
        placeholder=" "
        persistent-placeholder
        hide-details
      />
    </v-form>
  </common-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import CommonDialog from './CommonDialog.vue'

export default {
  name: 'LoginDialog',
  data: () => ({
    email: null,
    password: null
  }),
  methods: {
    ...mapActions('auth', ['authLogin']),

    open() {
      this.$refs.dialog.open()
    },

    async proceed() {
      const email = this.email
      const password = this.password
      await this.authLogin({ email, password })
    }
  },

  components: {
    CommonDialog
  }
}
</script>

<style scoped>
.v-form > *:not(:last-child) {
  margin-bottom: var(--app-gutter-md);
}
</style>
