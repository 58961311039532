<template>
  <v-sheet class="home">
    <!-- <v-spacer /> -->
    <contact-dialog ref="contactDialog" />
    <background-home class="background top" preserveAspectRatio="none" />
    <div class="intro">
      <logo-notes class="logo" />
      <div class="title">SongJelly</div>
      <div class="subtitle">Beta 0.1.4</div>
      <div class="buttons">
        <v-btn text to="search">Search</v-btn>
        <v-btn text to="drive">Drive</v-btn>
        <v-btn text to="doc">Documentation</v-btn>
        <v-btn text to="peer">Peer</v-btn>
        <v-btn text @click="openContact">Contact</v-btn>
      </div>
      <div class="footer">
        Made by
        <a href="https://www.quarkstudio.fr" target="blank">Quarkstudio</a>
      </div>
    </div>
    <background-home-bottom
      class="background bottom"
      preserveAspectRatio="none"
    />
  </v-sheet>
</template>

<script>
import logoNotes from '@/assets/img/logo-notes.svg'
import backgroundHome from '@/assets/img/background-home.svg'
import backgroundHomeBottom from '@/assets/img/background-home-bottom.svg'

import ContactDialog from '@/components/dialog/ContactDialog.vue'

export default {
  name: 'Home',
  components: {
    logoNotes,
    backgroundHome,
    backgroundHomeBottom,
    ContactDialog
  },
  methods: {
    openContact() {
      this.$refs.contactDialog.open()
    }
  }
}
</script>
<style scoped>
.home {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: var(--app-color-paper);
}
.background {
  width: 100vw;
  flex: 1;
}
.background.top {
  transform-origin: top;
  transform: scaleY(1);
}
.background.top > path {
  opacity: 1;
  fill: var(--v-accent-darken1);
}

.background.bottom {
  transform-origin: bottom;
  transform: scaleY(1);
}
.intro {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.buttons {
  display: flex;
  flex-direction: column;
}
.buttons > *:not(:last-child) {
  margin-bottom: 8px;
}
.logo {
  height: 163px;
}
.title {
  font-family: 'Pacifico', cursive !important;
  font-size: 40px !important;
  line-height: 60px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.subtitle {
  margin-bottom: 32px;
  font-size: small;
  color: darkgray;
}
.footer {
  margin-top: 32px;
  font-size: small;
  color: darkgray;
}
</style>
