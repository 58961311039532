import { Score } from '../utils/class-utils'
import router from '../router'

import cloneDeep from 'lodash/cloneDeep'

// =============================================================================
// =============================================================================
// =============================================================================

/**
 * - score : Data of the score (in database)
 * - report : Report of the renderer
 * - extends : Flag which define that score extends all screen (without UI).
 *             Not saved (as settings)
 */
const state = {
  score: null,
  report: null,
  tags: null,
  extends: false,

  settings: {
    renderer: 'staff',
    renderOptions: ['lyric', 'harmony', 'part', 'header'],
    screenOptions: ['status', 'progress', 'part', 'diagram'],
    countdown: 0
  }
}

const getters = {
  score: (state) => state.score || new Score(),
  report: (state) => state.report,
  tags: (state) => state.tags || [],
  scoreExtends: (state) => state.extends,
  //
  // Shortcuts
  //
  events(state) {
    return state.report?.events()
  },
  eventByIndex: (state) => (index) => {
    const eventById = state.report?.eventById()
    return eventById && eventById.get(index)
  },
  scoreSettings(state) {
    const settings = state.settings
    let result = {
      renderer: settings.renderer,
      renderOptions: settings.renderOptions
    }
    if (state.score) {
      const scoreSettings = state.score.settings
      Object.assign(result, {
        tempo: scoreSettings.tempo,
        transpose: scoreSettings.transpose,
        spacing: scoreSettings.spacing,
        layout: scoreSettings.layout
      })
    }
    return result
  },
  //
  // Settings
  //
  renderer: (state) => state.settings.renderer,
  renderOptions: (state) => state.settings.renderOptions,
  screenOptions: (state) => state.settings.screenOptions,
  countdown: (state) => state.settings.countdown,
  //
  // Settings of the score
  //
  tempo: (state) => state.score && state.score.settings.tempo,
  layout: (state) => state.score && state.score.settings.layout,
  transpose: (state) => state.score && state.score.settings.transpose,
  spacing: (state) => state.score && state.score.settings.spacing
}

const mutations = {
  setScore(state, value) {
    state.score = value
  },
  setScoreName(state, value) {
    state.score.name = value
  },
  setRaw(state, value) {
    state.score.raw = value
  },
  setTags(state, value) {
    state.tags = value
  },
  toggleExtends(state) {
    state.extends = !state.extends
  },
  destroy(state) {
    state.score = null
  },
  setReport(state, value) {
    state.report = value
  },
  //
  // Settings
  //
  menu: (state, value) => (state.settings.menu = value),
  preview: (state, value) => (state.settings.preview = value),
  scrollsync: (state, value) => (state.settings.scrollsync = value),
  countdown: (state, value) => (state.settings.countdown = value),

  renderer: (state, value) => (state.settings.renderer = value),
  renderOptions: (state, value) => (state.settings.renderOptions = value),
  screenOptions: (state, value) => (state.settings.screenOptions = value)
}

const actions = {
  setSettings({ commit }, { name, value }) {
    commit(name, value)
  },

  // ===========================================================================

  /**
   * Send notification to other stores.
   */

  notify({ dispatch }, type) {
    const details = { store: 'score', type, score: state.score }
    dispatch('player/on', details, { root: true })
    dispatch('cast/on', details, { root: true })
  },

  // ===========================================================================

  /**
   * listener from other stores
   */

  on({ dispatch }, { type, store, current }) {
    // const score = state.score
    // if (store == 'drive' && type === 'rename' && score) {
    //   const name = folder.items.find((item) => item.id === score.id).name
    //   commit('setScoreName', name)
    // }
    if (store === 'drive' && type === 'open-score') {
      dispatch('openScore', current)
    }
  },

  // ===========================================================================

  db({ dispatch }, payload) {
    const { action, ...args } = payload
    const route = router.currentRoute
    const storeName =
      route.params?.driveId === 'remote' ? 'driveRemote' : 'driveLocal'
    return dispatch(storeName + '/' + action, args, { root: true })
  },

  // ===========================================================================

  /**
   * Open score from database.
   *
   * Note : This function is triggered internally by notification from
   * drive-store.
   */
  async openScore({ commit, dispatch }, file) {
    const driveId = router.currentRoute.params.driveId
    const scoreId = router.currentRoute.params.scoreId
    const dbScore = await dispatch('db', {
      action: 'dbGet',
      storeName: 'scores',
      key: scoreId
    })
    let score
    if (!file) {
      //
      // Score has no file
      //
      score = new Score({
        id: scoreId,
        isOrphean: true
      })
    } else {
      const folderId = file.parent != driveId ? '/' + file.parent : ''
      score = new Score({
        id: scoreId,
        drive: `${driveId}${folderId}`,
        raw: '',
        exists: false
      })
      if (dbScore) {
        //
        // Score exists
        //
        score.draft = score.raw = dbScore.draft
        score.exists = true
        score.settings = Object.assign(score.settings, dbScore.settings)
      }
    }
    //
    // Commits
    //
    commit('setScore', score)
    return score
  },

  // ===========================================================================

  openFromPeer({ commit, dispatch }, peer) {
    const score = Score.fromPeer(peer)
    commit('setScore', score)
    dispatch('notify', 'open')
  },

  // ===========================================================================

  closeScore({ commit, dispatch }) {
    commit('destroy')
    dispatch('notify', 'close')
  },

  // ===========================================================================

  async rename({ state, dispatch }, newName) {
    if (!state.score.isOrphean) {
      const scoreId = router.currentRoute.params.scoreId
      await dispatch('db', {
        action: 'dbSet',
        storeName: 'drive',
        key: scoreId,
        property: 'name',
        value: newName
      })
      state.score.name = newName
    }
  },

  // ===========================================================================

  async createScore({ dispatch }, { id, draft }) {
    const obj = { id, draft }
    await dispatch('db', {
      action: 'dbAdd',
      storeName: 'scores',
      obj
    })
  },

  // ===========================================================================

  async saveDraft({ state, dispatch }) {
    const score = state.score
    if (score.raw === score.draft) return
    score.draft = score.raw
    if (!score.exists) {
      dispatch('createScore', { id: score.id, draft: score.draft })
      state.score.exists = true
    } else {
      await dispatch('db', {
        action: 'dbSet',
        storeName: 'scores',
        key: score.id,
        property: 'draft',
        value: score.draft
      })
    }
  },

  // ===========================================================================

  async setScoreSettings({ state, dispatch }, [name, value]) {
    const score = state.score
    const oldSettings = score.settings
    let oldValue
    //
    // Check changes
    //
    if (oldSettings) {
      oldValue = oldSettings[name]
    } else if (oldValue == value) {
      return
    }
    //
    // Set state
    //
    if (oldSettings) {
      score.settings = cloneDeep(oldSettings)
      score.settings[name] = value
    } else {
      score.settings = { [name]: value }
    }
    //
    // Update database
    //
    try {
      await dispatch('db', {
        action: 'dbSet',
        storeName: 'scores',
        key: score.id,
        property: 'settings',
        value: score.settings
      })
    } catch (e) {
      console.error(
        `Unable to set score settings '${name}'' : ${e.error.message}`
      )
    }
  },

  // ===========================================================================

  async setReport({ dispatch, commit }, report) {
    commit('setReport', report)
    if (!report) return

    const tags = []
    const taggedProps = [
      { prop: 'hasLyric', tag: 'lyric' },
      { prop: 'hasHarmony', tag: 'harmony' },
      { prop: 'hasNote', tag: 'melody' },
      { prop: 'hasMetric', tag: 'metric' }
    ]
    taggedProps.forEach((val) => {
      if (report[val.prop]) tags.push(val.tag)
    })
    if (report.voices.length > 1) {
      tags.push(`${report.voices.length}-voices`)
    }
    if (state.score && !state.score.isOrphean) {
      await dispatch('db', {
        action: 'dbSet',
        storeName: 'drive',
        key: state.score.id,
        property: 'tags',
        value: tags
      })
    }
    commit('setTags', tags)
    dispatch('notify', 'report')
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
