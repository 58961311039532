import { romanize } from '../utils/format-utils'
import chordDB from '@tombatossals/chords-db/lib/guitar.json'

const chordPositionMap = new Map()

const positionPatches = {
  Baug7: [{ index: 1, prop: 'fingers', value: [0, 1, 4, 1, 2, 3] }]
}

for (const key in chordDB.chords) {
  const chordList = chordDB.chords[key]
  chordList.forEach((chord) => {
    const chordId = chord.key + chord.suffix
    const patches = positionPatches[chordId]
    patches?.forEach((patch) => {
      chord.positions[patch.index][patch.prop] = patch.value
    })
    chordPositionMap.set(chordId, chord.positions)
  })
}

const transformRoot = {
  Db: 'C#',
  'D#': 'Eb',
  Gb: 'F#',
  'G#': 'Ab',
  'A#': 'Bb',
  'C#': 'C#',
  'F#': 'F#'
}

const suffixTransform = {
  '7sus': '7sus4',
  M: 'major',
  m: 'minor',
  4: 'sus4',
  2: 'sus2',
  M7: 'maj7',
  mM7: 'mmaj7',
  '+': 'aug',
  '°': 'dim',
  sus: 'sus4',
  msus: 'sus4',
  '7+': 'aug7',
  '9#5': 'aug9',
  '°7': 'dim7'
}

const ALL_NOTES = [
  'C',
  'C#',
  'D',
  'D#',
  'E',
  'F',
  'F#',
  'G',
  'G#',
  'A',
  'A#',
  'B'
]

const NORMALIZED_NOTES = {
  Cb: 'B',
  Db: 'C#',
  Eb: 'D#',
  Fb: 'E',
  Gb: 'F#',
  Ab: 'G#',
  Bb: 'A#'
}

const MD_OPTIONS = {
  fretCount: chordDB.main.fretsOnChord,
  instrument: chordDB.main.name,
  tunings: chordDB.tunings.standard.map((t) => t.charAt(0)),
  stringCount: chordDB.main.strings,
  fretGap: 26,
  stringGap: 26,
  fingerSize: 16,
  paddingLeft: 28,
  paddingRight: 16,
  paddingTop: 30,
  paddingBottom: 20,
  stringThickness: 2,
  fretThickness: 1,
  verticalHeaderGap: 10,
  horizontalHeaderGap: 5,
  fontSize: 11,
  fingerFontSize: 10,
  fretFontSize: 10,
  fingerLabelPosition: 'finger',
  darkColor: '#666666',
  lightColor: '#FFFFFF',
  muteColor: '#66666666',
  fretColor: '#66666633',
  stringColor: '#333333'
}

const SM_OPTIONS = Object.assign({}, MD_OPTIONS, {
  fretGap: 18,
  stringGap: 18,
  fontSize: 9,
  fingerFontSize: 7,
  fretFontSize: 10,
  fingerSize: 12
})

const XS_OPTIONS = Object.assign({}, MD_OPTIONS, {
  fretGap: 10,
  stringGap: 10,
  fontSize: 0,
  fretFontSize: 10,
  fingerFontSize: 0,
  fingerSize: 8,
  paddingTop: 2,
  paddingBottom: 2,
  paddingRight: 6
})

const OPTIONS = {
  md: MD_OPTIONS,
  sm: SM_OPTIONS,
  xs: XS_OPTIONS
}

const getNote = (fret, base) => {
  const baseIndex = ALL_NOTES.indexOf(base)
  return ALL_NOTES[(baseIndex + fret) % 12]
}

const normalizeNote = (note) => {
  return NORMALIZED_NOTES[note] || note
}

export default (canvas, chord, diagramIndex, size) => {
  const raw = chord.current.raw
  chord = chord.current.chord
  if (chord.error) return
  let root = chord.input.rootNote
  if (root in transformRoot) root = transformRoot[root]
  let suffix = chord.input.descriptor || 'major'
  suffix = suffix.replace(/\(|\)/g, '')
  if (suffix in suffixTransform) suffix = suffixTransform[suffix]
  //
  // Clear the canvas
  //
  const c = canvas.getContext('2d')
  const o = OPTIONS[size || 'md']
  const degrees = []
  c.clearRect(0, 0, canvas.width, canvas.height)
  c.fillStyle = o.darkColor
  //
  // Check Root
  //
  // const data = chordDB
  // if (!data.chords[root]) {
  //   console.warn('WTF ! No root match for :' + raw)
  //   return 0
  // }
  //
  // Check suffixes
  //
  // if (data.suffixes.indexOf(suffix) === -1) {
  //   console.warn('WTF ! No suffix match for :' + suffix)
  //   return 0
  // }
  //
  // Get positions
  //
  const chordId = root + suffix
  const positions = chordPositionMap.get(chordId)
  if (!positions?.length) {
    console.warn(
      `No diagram found for ${raw} with the id ${chordId}`,
      chordPositionMap
    )
    return 0
  }
  //
  // Set canvas height
  //
  let canvasHeight = o.fretCount * (o.fretGap + o.fretThickness)
  let canvasWidth = (o.stringCount - 1) * (o.stringGap + o.stringThickness)
  canvasWidth += o.paddingLeft + o.paddingRight
  canvasHeight += o.paddingTop + o.paddingBottom
  canvas.width = canvasWidth
  canvas.height = canvasHeight
  //
  // Loop through each strings
  //
  const position = positions[Math.min(diagramIndex, positions.length - 1)]
  for (let string = 0; string < o.stringCount; string++) {
    const fret = position.frets[string]
    const isMuted = fret === -1
    const isEmpty = position.fingers[string] === 0 && !isMuted
    const baseFret = isEmpty ? 0 : position.baseFret - 1
    let stringX = string * (o.stringGap + o.stringThickness)
    stringX += o.paddingLeft
    c.beginPath()
    c.moveTo(stringX, o.paddingTop)
    c.lineTo(stringX, canvasHeight - o.paddingBottom)
    // c.setLineDash(isMuted ? [5, 2] : [])
    c.strokeStyle = isMuted ? o.muteColor : o.stringColor
    c.closePath()
    c.stroke()
    //
    // Tuning label
    //

    let degree, fingerNote
    if (!isMuted) {
      const base = o.tunings[string]
      fingerNote = getNote(baseFret + fret, base)
      const intervals = chord.normalized.intervals
      chord.normalized.notes.some((note, j) => {
        if (fingerNote === normalizeNote(note)) {
          degree = intervals[j]
          return true
        }
      })
      if (!degree) {
        console.warn('WTF ! No degree found for ' + fingerNote, {
          chord,
          position
        })
        degree = '?'
      }
    }
    degrees.push(degree)
    const tuningLabel = isMuted ? 'X' : fingerNote
    let tuningX = stringX
    let tuningY = o.paddingTop - o.verticalHeaderGap
    c.textBaseline = 'bottom'
    c.fillStyle = isMuted ? o.muteColor : o.darkColor
    c.textAlign = 'center'
    c.font = `${o.fontSize}px sans-serif`
    c.fillText(tuningLabel, tuningX, tuningY)

    if (fret === 0) {
      const headerSize = o.fingerSize
      if (degree === '1') {
        const rectX = Math.round(tuningX - headerSize / 2)
        const rectY = Math.round(tuningY - (headerSize + o.fontSize) / 2)
        c.rect(rectX - 0.5, rectY - 1.5, headerSize, headerSize)
      } else {
        const circleY = tuningY - o.fontSize / 2 - 1
        c.beginPath()
        c.arc(tuningX, circleY, headerSize / 2, 0, 2 * Math.PI, false)
      }
      c.strokeStyle = o.muteColor
      c.closePath()
      c.stroke()
    }
    //
    // Finger label
    //
    const fingerLabel = isMuted ? 'X' : degree
    let fingerX = stringX
    let fingerY = canvasHeight - o.paddingBottom + o.verticalHeaderGap
    c.textBaseline = 'top'
    c.fillText(fingerLabel, fingerX, fingerY)
  }

  //
  // Frets
  //
  for (let fret = 0; fret < o.fretCount + 1; fret++) {
    let fretY = fret * (o.fretGap + o.fretThickness)
    fretY -= o.fretThickness / 2
    fretY += o.paddingTop
    let fretX = o.paddingLeft
    c.beginPath()
    c.strokeStyle = o.fretColor
    c.moveTo(fretX, fretY)
    c.lineTo(canvasWidth - o.paddingRight, fretY)
    c.setLineDash([])
    c.stroke()
    //
    // Fret label
    //
    if (fret < o.fretCount) {
      const fretLabel = romanize(position.baseFret + fret)
      fretY += o.fretGap / 2
      fretX -= o.fingerSize / 2 + o.horizontalHeaderGap
      c.textBaseline = 'middle'
      c.textAlign = 'right'
      c.fillStyle = o.darkColor
      c.font = `${o.fretFontSize}px sans-serif`
      c.fillText(fretLabel, fretX, fretY + 1)
    }
  }
  //
  // Fingers
  //
  position.fingers.forEach((finger, i) => {
    const fret = position.frets[i]
    if (fret > 0) {
      let fingerX = i * (o.stringGap + o.stringThickness)
      fingerX += o.paddingLeft
      let fingerY = (fret - 0.5) * (o.fretGap + o.fretThickness)
      fingerY += o.paddingTop - 0.5
      c.fillStyle = o.darkColor
      c.beginPath()
      if (degrees[i] === '1') {
        const rectX = Math.round(fingerX - o.fingerSize / 2)
        const rectY = Math.round(fingerY - o.fingerSize / 2)
        c.rect(rectX, rectY, o.fingerSize, o.fingerSize)
      } else {
        c.arc(fingerX, fingerY, o.fingerSize / 2, 0, 2 * Math.PI, false)
      }
      c.fill()
      //
      // Finger label
      //
      const fingerLabel = position.fingers[i]
      c.textBaseline = 'middle'
      c.textAlign = 'center'
      c.fillStyle = o.lightColor
      c.font = `${o.fingerFontSize}px sans-serif`
      c.fillText(fingerLabel, fingerX, fingerY)
    }
  })
  return positions.length
}
