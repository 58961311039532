const state = {
  lastMessage: null
}

const mutations = {
  setLastMessage(state, value) {
    this.lastMessage = value
  }
}

const actions = {
  contactSend({ state, commit }, { name, email, message }) {
    return new Promise((resolve, reject) => {
      const body = `${name}|${email}|${message}`
      if (state.lastMessage === body) {
        reject({
          name: 'sent-error',
          message: 'This message has already been sent !'
        })
      }

      const formData = new FormData()
      formData.append('name', name)
      formData.append('email', email)
      formData.append('message', message)

      fetch('/api/sendMessage', {
        body: formData,
        method: 'post'
      })
        .then((res) => {
          if (res.status === 200) {
            commit('setLastMessage', body)
            resolve()
          } else {
            reject({
              name: 'server-error',
              message:
                'The server is not working properly. Your message could not be sent.'
            })
          }
        })
        .catch(() => {
          this.error = 'network-error'
          reject({
            name: 'network-error',
            message:
              'The network is not working properly. Your message could not be sent.'
          })
        })
    })
  }
}

export default {
  namespaced: true,
  actions,
  mutations,
  state
}
