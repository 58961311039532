<template>
  <common-dialog ref="dialog" no-action width="400px">
    <!-- VIEW OFF -->
    <div class="view" v-if="isOff || isConnecting">
      <div>Broadcast the current score to another devices.</div>
      <v-text-field
        label="Id of the broadcast"
        v-model="castName"
        placeholder=" "
        persistent-placeholder
        hide-details
        @keypress="matchPattern($event)"
      />
      <v-btn
        :disabled="!castName"
        color="accent"
        @click="connect()"
        :loading="isConnecting"
      >
        Let's go !
      </v-btn>
    </div>
    <!-- VIEW QRCODE -->
    <div class="view qrcode" v-else-if="!showUsers">
      <div>The current score is broadcasted at :</div>
      <div class="url">{{ url }}</div>
      <canvas ref="canvas" />
      <v-btn color="accent" @click="castClose()">Stop broadcasting</v-btn>
      <v-btn text @click="showUsers = true">show users</v-btn>
    </div>
    <!-- VIEW USERS -->
    <div class="view users" v-else>
      <div>Peer connected to your channel</div>
      <v-list v-if="castPeers.length">
        <v-list-item v-for="(peer, peerIndex) in castPeers" :key="peerIndex">
          <v-list-item-content>
            <v-list-item-title>{{ peer.label }}</v-list-item-title>
            <!-- <v-list-item-subtitle>{{ peer.id }}</v-list-item-subtitle> -->
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="castDisconnectPeer(peer.id)"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <div v-else class="nobody-message">Nobody is connected</div>
      <v-btn text @click="showUsers = false">hide users</v-btn>
    </div>
  </common-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import CommonDialog from './CommonDialog.vue'
import QRCode from 'qrcode'

export default {
  name: 'CastDialog',
  components: {
    CommonDialog
  },
  data: () => ({
    showUsers: false,
    castName: ''
  }),
  computed: {
    ...mapGetters('app', ['settings']),
    ...mapGetters('cast', ['castId', 'castStatus', 'castPeers']),

    isOff() {
      return this.castStatus === 'off'
    },

    isConnecting() {
      return this.castStatus === 'connecting'
    },

    url() {
      return `${window.location.host}/peer/${this.castId}`
    }

    // users() {
    //   return [...this.castPeers].map((v) => ({ id: v[0], ...v[1] }))
    // }
  },
  watch: {
    castStatus(val) {
      if (val === 'on') {
        this.$nextTick(this.updateQRCode)
      }
    },
    showUsers(val) {
      if (!val) {
        this.$nextTick(this.updateQRCode)
      }
    }
  },
  mounted() {},
  methods: {
    ...mapActions('cast', ['castConnect', 'castClose', 'castDisconnectPeer']),

    updateQRCode() {
      const element = this.$refs.canvas
      const url = this.url
      if (url && element) {
        const o = { margin: 0 }
        QRCode.toCanvas(element, url, o, function (error) {
          if (error) console.error(error)
        })
      }
    },

    open() {
      this.castName = this.settings.cast.castId || ''
      this.$refs.dialog.open()
      this.$nextTick(this.updateQRCode)
    },

    connect() {
      this.castConnect(this.castName)
    },

    matchPattern(e) {
      let char = String.fromCharCode(e.keyCode)
      if (/^[A-Za-z_-\d]+$/.test(char)) return true
      else e.preventDefault()
    }
  }
}
</script>

<style scoped>
.view {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.view > *:not(:last-child) {
  margin-bottom: var(--app-gutter-sm);
}
.url {
  color: black;
}
.qrcode {
  align-self: center;
  text-align: center;
}
.qrcode canvas {
  margin-top: var(--app-gutter-sm);
  align-self: center;
}
.v-btn:first-of-type {
  margin-top: var(--app-gutter-md);
}
.v-btn {
  align-self: center;
}
.users > div:first-child {
  align-self: flex-start;
}
.v-list {
  margin-top: var(--app-gutter-md);
  align-self: stretch;
  padding: 0;
  border-top: solid 1px var(--app-color-line);
}
.v-list-item {
  border-bottom: solid 1px var(--app-color-line);
}
.v-text-field {
  align-self: stretch;
}
.nobody-message {
  padding-top: var(--app-gutter-sm);
  font-style: italic;
}
</style>
