import set from 'lodash/set'
import get from 'lodash/get'

// ============================================================================
// ============================================================================
// ============================================================================

export const List = class extends Array {
  get first() {
    return this[0]
  }
  set first(val) {
    this[0] = val
  }
  get last() {
    return this[this.length - 1]
  }
  set last(val) {
    this[this.length - 1] = val
  }
}

// ============================================================================
// ============================================================================
// ============================================================================

export const Manifest = class extends Array {
  constructor() {
    super()
    this.symbolIndex = new Map()
    this.nameIndex = new Map()
    this.allSymbols = []
  }
  addItem(item) {
    this.allSymbols.push(item.symbol)
    this.symbolIndex.set(item.symbol, item)
    this.nameIndex.set(item.name, item)
    this.push(item)
  }
  set(items) {
    for (let i = 0; i < items.length; i++) {
      this.addItem(items[i])
    }
  }
}

// ============================================================================
// ============================================================================
// ============================================================================

export class Paragraph extends List {} // contains line
export class Line {}
export class Flow {}
export class Slot {}
export class Division extends List {} // contains slot or division
// export class Event extends List {} // contains slots

let warningPool
export class Warning {
  static setPool(pool) {
    warningPool = pool
  }
  static throw(args) {
    warningPool.push(new Warning(args))
  }
  constructor(args) {
    Object.assign(this, args)
  }
}

export class Settings {
  constructor() {
    this.chars = {
      spacer: ' ',
      empty: '-',
      string: '`',
      division: { open: '[', close: ']' },
      floating: '>',
      context: '|',
      delimiter: '|',
      extends: '>',
      breakline: '¶'
    }
    this.manifest = new Manifest()
    this.updates = ['init']
  }
  clearUpdates() {
    this.updates.length = 0
  }
  update(path, value) {
    const current = get(path)
    if (current != value) {
      this.updates.push(path)
    }
    set(this, path, value)
  }
}
