<template>
  <div class="app-icon">
    <component class="custom" :is="value" v-if="isCustom" max-width="24" />
    <v-icon v-else> mdi-{{ value }}</v-icon>
  </div>
</template>

<script>
import charAnchor from '@/assets/icons/char-anchor.svg'
import charComments from '@/assets/icons/char-comments.svg'
import charDownArrow from '@/assets/icons/char-down-arrow.svg'
import charEmpty from '@/assets/icons/char-empty.svg'
import charMeasure from '@/assets/icons/char-measure.svg'
import charMedian from '@/assets/icons/char-median.svg'
import charPart from '@/assets/icons/char-part.svg'
import charTempo from '@/assets/icons/char-tempo.svg'
import charUpArrow from '@/assets/icons/char-up-arrow.svg'

import codeCopy from '@/assets/icons/code-copy.svg'
import codePrettier from '@/assets/icons/code-prettier.svg'
import copy from '@/assets/icons/copy.svg'
import guitarBodyHorizontal from '@/assets/icons/guitar-body-horizontal.svg'
import guitarDiagram from '@/assets/icons/guitar-diagram.svg'
import guitarHeadHorizontal from '@/assets/icons/guitar-head-horizontal.svg'
import guitarHead from '@/assets/icons/guitar-head.svg'
import guitar from '@/assets/icons/guitar.svg'
import hideRightDrawer from '@/assets/icons/hide-right-drawer.svg'
import highlightBeat from '@/assets/icons/highlight-beat.svg'
import highlightEvent from '@/assets/icons/highlight-event.svg'
import highlightMeasure from '@/assets/icons/highlight-measure.svg'
import highlightNone from '@/assets/icons/highlight-none.svg'
import highlightRow from '@/assets/icons/highlight-row.svg'
import layout2Cols from '@/assets/icons/layout-2-cols.svg'
import layout3Cols from '@/assets/icons/layout-3-cols.svg'
import layout4Cols from '@/assets/icons/layout-4-cols.svg'
import layout5Cols from '@/assets/icons/layout-5-cols.svg'
import layout6Cols from '@/assets/icons/layout-6-cols.svg'
import layout from '@/assets/icons/layout.svg'
import paste from '@/assets/icons/paste.svg'
import preview from '@/assets/icons/preview.svg'
import renderAuto from '@/assets/icons/render-auto.svg'
import renderChart from '@/assets/icons/render-chart.svg'
import renderLyric from '@/assets/icons/render-lyric.svg'
import renderOptions from '@/assets/icons/render-options.svg'
import renderStaff from '@/assets/icons/render-staff.svg'
import scoreDisplayChart from '@/assets/icons/score-display-chart.svg'
import scoreDisplayLyric from '@/assets/icons/score-display-lyric.svg'
import scoreDisplay from '@/assets/icons/score-display.svg'
import scoreExtends from '@/assets/icons/score-extends.svg'
import scoreSelect from '@/assets/icons/score-select.svg'
import scoreSpeaker from '@/assets/icons/score-speaker.svg'
import screenLayout from '@/assets/icons/screen-layout.svg'
import showRightDrawer from '@/assets/icons/show-right-drawer.svg'
import spacingDefault from '@/assets/icons/spacing-default.svg'
import spacingDense from '@/assets/icons/spacing-dense.svg'
import spacingStrict from '@/assets/icons/spacing-strict.svg'
import storageCloud from '@/assets/icons/storage-cloud.svg'
import storageLocal from '@/assets/icons/storage-local.svg'
import switchOff from '@/assets/icons/switch-off.svg'
import switchOn from '@/assets/icons/switch-on.svg'
import tempoVolume from '@/assets/icons/tempo-volume.svg'
import transpose from '@/assets/icons/transpose.svg'

import kebabCase from 'lodash/kebabCase'

const components = {
  charAnchor,
  charComments,
  charDownArrow,
  charEmpty,
  charMeasure,
  charMedian,
  charPart,
  charTempo,
  charUpArrow,
  codeCopy,
  codePrettier,
  copy,
  guitarHead,
  guitarHeadHorizontal,
  guitarBodyHorizontal,
  guitarDiagram,
  guitar,
  highlightNone,
  highlightRow,
  highlightMeasure,
  highlightBeat,
  highlightEvent,
  layout,
  layout2Cols,
  layout3Cols,
  layout4Cols,
  layout5Cols,
  layout6Cols,
  paste,
  preview,
  renderAuto,
  renderLyric,
  renderChart,
  renderStaff,
  renderOptions,
  scoreDisplay,
  scoreDisplayLyric,
  scoreDisplayChart,
  scoreExtends,
  scoreSelect,
  scoreSpeaker,
  screenLayout,
  spacingDefault,
  spacingDense,
  spacingStrict,
  storageCloud,
  storageLocal,
  tempoVolume,
  transpose,
  showRightDrawer,
  hideRightDrawer,
  switchOn,
  switchOff
}

const dict = new Set(Object.keys(components).map((v) => kebabCase(v)))

export default {
  name: 'AppIcon',
  components,
  props: {
    value: String
  },
  computed: {
    isCustom() {
      return this.value && dict.has(this.value)
    }
  }
}
</script>

<style scoped>
.app-icon {
  position: relative;
}
.app-icon,
.custom {
  min-width: 24px !important;
  min-height: 24px !important;
  max-width: 24px !important;
  max-height: 24px !important;
}
.custom {
  fill: #555555;
  /* opacity: 0.8; */
}
.app-icon.large,
.large .custom {
  min-width: 48px !important;
  min-height: 48px !important;
  max-width: 48px !important;
}

.active .custom {
  fill: white;
}

.v-icon {
  opacity: 1;
  color: #444444 !important;
  margin-left: -2px;
  /* margin-top: 1px; */
}

/* .active .v-icon {
  color: white !important;
}

.active .app-icon:before {
  content: ' ';
  position: absolute;
  left: -4px;
  right: -4px;
  top: -4px;
  bottom: -4px;
  background: var(--v-accent-base);
} */
</style>
