const NOTES_MAP = [
  { raw: 'C', isTone: true, semitone: 0 },
  { raw: 'C#', isTone: false, semitone: 1 },
  { raw: 'D', isTone: true, semitone: 2 },
  { raw: 'D#', isTone: false, semitone: 3 },
  { raw: 'E', isTone: true, semitone: 4 },
  { raw: 'F', isTone: true, semitone: 5 },
  { raw: 'F#', isTone: false, semitone: 6 },
  { raw: 'G', isTone: true, semitone: 7 },
  { raw: 'G#', isTone: false, semitone: 8 },
  { raw: 'A', isTone: true, semitone: 9 },
  { raw: 'A#', isTone: false, semitone: 10 },
  { raw: 'B', isTone: true, semitone: 11 }
]

const MD_OPTIONS = {
  paddingLeft: 9,
  paddingRight: 9,
  paddingTop: 25,
  paddingBottom: 15,

  strokeThickness: 1,
  strokeColor: '#666666',

  keyWidth: 12,
  keyHeight: 100,
  keyCorner: 2,
  darkKeyHeight: 60,
  darkKeyWidth: 8,
  whiteKeyColor: 'white',
  darkKeyColor: '#666666',

  fingerSize: 4,
  fingerWhiteColor: '#f5f5f5',
  fingerDarkColor: '#666666',
  fingerOffsetY: 15,

  fontSize: 11,
  labelGap: 5
}

const SM_OPTIONS = Object.assign({}, MD_OPTIONS, {
  keyWidth: 10,
  keyHeight: 80,
  darkKeyHeight: 50,
  darkKeyWidth: 6,
  fingerSize: 3,
  paddingTop: 2,
  paddingBottom: 2,
  paddingLeft: 9,
  paddingRight: 9
})

const XS_OPTIONS = Object.assign({}, MD_OPTIONS, {
  keyWidth: 6,
  keyHeight: 50,
  darkKeyHeight: 30,
  darkKeyWidth: 4,
  fingerSize: 2,
  paddingTop: 2,
  paddingBottom: 2,
  paddingLeft: 9,
  paddingRight: 9
})

const OPTIONS = {
  md: MD_OPTIONS,
  sm: SM_OPTIONS,
  xs: XS_OPTIONS
}

const NORMALIZED_NOTES = {
  Cb: 'B',
  Db: 'C#',
  Eb: 'D#',
  Fb: 'E',
  Gb: 'F#',
  Ab: 'G#',
  Bb: 'A#'
}

const normalizeNote = (note) => {
  return NORMALIZED_NOTES[note] || note
}

export default (canvas, chord, diagramIndex, size, verbose) => {
  if (verbose) console.log('CHORDS', chord)
  // stCount : number of semitone of the scale : 12
  // stKeyboard : number of semiton of the keyboard (two octava)
  // stChord : Number of semitone of the chord
  // stOffset : Offset in semitone between first note of the scale
  //            an first note of the chord
  chord = chord.current.chord
  if (chord.error) return
  let root = normalizeNote(chord.input.rootNote)
  const semitones = chord.normalized.semitones
  const stCount = NOTES_MAP.length
  const stKeyboard = stCount * 2 // semitone of the keyboard : 24
  const stChord = semitones[semitones.length - 1] // semitone of the chord
  const stOffset = NOTES_MAP.findIndex((n) => n.raw === root) // Offset of
  let toneKeyCount = 15
  const keys = []
  //
  // Inspect each note of the chord
  //
  semitones.forEach((v, i) => {
    //
    // Add the note (of the chord) to the result
    //
    const current = {
      ...NOTES_MAP[(v + stOffset) % stCount],
      isDown: true,
      interval: chord.normalized.intervals[i]
    }
    keys.push(current)
    //
    // Add others notes of the scale between the current and the next note
    // of the chord
    //
    const next = NOTES_MAP[(semitones[i + 1] + stOffset) % stCount]
    if (next) {
      const from = current.semitone + 1
      let to = next.semitone
      if (to < from) to += stCount
      for (var j = from; j < to; j++) {
        keys.push(NOTES_MAP[j % stCount])
      }
    }
  })
  //
  // Add padding (notes outside of the chord) to have 2 octavas in the
  // result.
  //
  const padCount = stKeyboard - stChord
  for (var i = 0; i < padCount; i++) {
    const isLast = i === padCount - 1
    const insertBefore = (!isLast && i % 2) || (isLast && !keys[0].isTone)
    if (insertBefore) {
      let prev = (keys[0].semitone + stCount - 1) % stCount
      keys.unshift(NOTES_MAP[prev])
    } else {
      let next = (keys[keys.length - 1].semitone + 1) % stCount
      keys.push(NOTES_MAP[next])
    }
  }
  //
  // Once the result is set, we can draw it
  //
  const o = OPTIONS[size || 'md']
  const c = canvas.getContext('2d')
  c.clearRect(0, 0, canvas.width, canvas.height)

  let canvasHeight = o.keyHeight + o.paddingTop + o.paddingBottom
  let canvasWidth = o.paddingLeft + o.paddingRight + toneKeyCount * o.keyWidth

  canvas.width = canvasWidth
  canvas.height = canvasHeight

  let posX = o.paddingLeft + 0.5
  let posY = o.paddingTop + 0.5
  const shapes = { white: [], dark: [], fingers: [] }
  //
  // Shapes of the keyboard are stored to be drawn by layer
  //
  keys.forEach((note) => {
    let shape
    if (note.isTone) {
      shape = {
        top: posY,
        left: posX,
        right: posX + o.keyWidth,
        bottom: posY + o.keyHeight
      }
      shapes.white.push(shape)
      posX += o.keyWidth
    } else {
      shape = {
        isDark: true,
        top: posY,
        left: posX - o.darkKeyWidth / 2,
        right: posX + o.darkKeyWidth / 2,
        bottom: posY + o.darkKeyHeight
      }
      shapes.dark.push(shape)
    }
    if (note.isDown) {
      //
      // Finger
      //
      let fingerY = posY - o.fingerOffsetY
      fingerY += note.isTone ? o.keyHeight : o.darkKeyHeight
      let fingerX = shape.left + (shape.right - shape.left) / 2
      shapes.fingers.push({
        left: fingerX,
        top: fingerY,
        color: note.isTone ? o.fingerDarkColor : o.fingerWhiteColor
      })
      //
      // Note raw label
      //
      const noteLabelX = fingerX
      const noteLabelY = posY - o.labelGap
      c.textBaseline = 'bottom'
      c.fillStyle = o.darkKeyColor
      c.textAlign = 'center'
      c.font = `${o.fontSize}px sans-serif`
      c.fillText(note.raw, noteLabelX, noteLabelY)
      //
      // Interval raw label
      //
      const intervalLabelX = fingerX
      const intervalLabelY = posY + o.keyHeight + o.labelGap
      c.textBaseline = 'top'
      c.fillStyle = o.darkKeyColor
      c.textAlign = 'center'
      c.font = `${o.fontSize}px sans-serif`
      c.fillText(note.interval, intervalLabelX, intervalLabelY)
    }
  })

  const keyShapes = [...shapes.white, ...shapes.dark]

  keyShapes.forEach((s) => {
    c.beginPath()
    c.moveTo(s.left, s.top)
    c.lineTo(s.right, s.top)
    c.arcTo(s.right, s.bottom, s.left, s.bottom, o.keyCorner)
    c.arcTo(s.left, s.bottom, s.left, s.top, o.keyCorner)
    c.closePath()
    c.lineWidth = o.strokeThickness
    c.strokeStyle = o.strokeColor
    c.stroke()
    if (s.isDark) {
      c.fillStyle = o.darkKeyColor
      c.fill()
    }
  })

  shapes.fingers.forEach((s) => {
    c.beginPath()
    c.arc(s.left, s.top, o.fingerSize, 0, 2 * Math.PI)
    c.closePath()
    c.fillStyle = s.color
    c.fill('nonzero')
  })

  if (verbose) {
    console.log('KEYS', keys)
  }
}
