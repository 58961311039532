const state = {
  settings: {
    panel: 0,
    diagram: 0
  }
}

const getters = {
  //
  // Settings
  //
  panel: (state) => state.settings.panel,
  diagram: (state) => state.settings.diagram
}

const mutations = {
  //
  // Settings
  //
  panel: (state, value) => (state.settings.panel = value),
  diagram: (state, value) => (state.settings.diagram = value)
}

const actions = {
  setSettings({ commit }, { name, value }) {
    commit(name, value)
  }
}

export default { namespaced: true, getters, state, mutations, actions }
