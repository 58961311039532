import { clampVolume } from '../utils/store-utils'

const NOTES = [
  { note: 'A0', module: import(`../assets/synth/A0.mp3`) },
  { note: 'C1', module: import(`../assets/synth/C1.mp3`) },
  { note: 'D#1', module: import(`../assets/synth/Ds1.mp3`) },
  { note: 'F#1', module: import(`../assets/synth/Fs1.mp3`) },
  { note: 'A1', module: import(`../assets/synth/A1.mp3`) },
  { note: 'C2', module: import(`../assets/synth/C2.mp3`) },
  { note: 'D#2', module: import(`../assets/synth/Ds2.mp3`) },
  { note: 'F#2', module: import(`../assets/synth/Fs2.mp3`) },
  { note: 'A2', module: import(`../assets/synth/A2.mp3`) },
  { note: 'C3', module: import(`../assets/synth/C3.mp3`) },
  { note: 'D#3', module: import(`../assets/synth/Ds3.mp3`) },
  { note: 'F#3', module: import(`../assets/synth/Fs3.mp3`) },
  { note: 'A3', module: import(`../assets/synth/A3.mp3`) },
  { note: 'C4', module: import(`../assets/synth/C4.mp3`) },
  { note: 'D#4', module: import(`../assets/synth/Ds4.mp3`) },
  { note: 'F#4', module: import(`../assets/synth/Fs4.mp3`) },
  { note: 'A4', module: import(`../assets/synth/A4.mp3`) },
  { note: 'C5', module: import(`../assets/synth/C5.mp3`) },
  { note: 'D#5', module: import(`../assets/synth/Ds5.mp3`) },
  { note: 'F#5', module: import(`../assets/synth/Fs5.mp3`) },
  { note: 'A5', module: import(`../assets/synth/A5.mp3`) },
  { note: 'C6', module: import(`../assets/synth/C6.mp3`) },
  { note: 'D#6', module: import(`../assets/synth/Ds6.mp3`) },
  { note: 'F#6', module: import(`../assets/synth/Fs6.mp3`) },
  { note: 'A6', module: import(`../assets/synth/A6.mp3`) },
  { note: 'C7', module: import(`../assets/synth/C7.mp3`) },
  { note: 'D#7', module: import(`../assets/synth/Ds7.mp3`) },
  { note: 'F#7', module: import(`../assets/synth/Fs7.mp3`) },
  { note: 'A7', module: import(`../assets/synth/A7.mp3`) },
  { note: 'C8', module: import(`../assets/synth/C8.mp3`) }
]

let Tone
let sampler

const STATUS = { off: 0, starting: 1, started: 2 }

const state = {
  channels: [],
  status: STATUS.off,

  settings: {
    volume: 80
  }
}

const getters = {
  status(state) {
    return state.status
  },
  // SETTINGS
  volume: (state) => state.settings.volume
}

const mutations = {
  channels(state, { channel, name, id }) {
    if (isNaN(channel)) {
      state.channels = []
    } else {
      state.channels[channel] = name ? { name, id } : null
    }
  },
  setStatus: (state, value) => (state.status = value),
  //
  // Settings
  //
  volume: (state, value) => {
    state.settings.volume = value
    if (sampler) {
      sampler.volume.value = clampVolume(value)
    }
  }
}

const actions = {
  setSettings({ commit }, { name, value }) {
    commit(name, value)
  },

  // ===========================================================================

  async on({ dispatch, rootGetters }, { report, head, type, playing }) {
    const isMuted = !state.settings.volume
    const isStaff = rootGetters['score/renderer'] === 'staff'
    const tempoRatio = rootGetters['player/tempoRatio']
    if (isMuted || !isStaff) return
    if (state.status === STATUS.off) {
      await dispatch('start')
    }
    //
    // On progress
    //
    if (
      state.status === STATUS.started &&
      (type === 'play' || type === 'progress')
    ) {
      if (!report.blocMap) {
        console.warn('WTF ! No blocmap !')
        return
      }
      const blocMap = report.blocMap()
      const blocs = blocMap.get(head)
      let channelCount = 0
      blocs.forEach((bloc) => {
        if (bloc.isNote) {
          if (playing) {
            if (bloc.head.isRest) {
              dispatch('triggerRelease', channelCount)
            } else {
              if (!bloc.tie) {
                dispatch('triggerAttack', {
                  id: bloc.id,
                  midi: bloc.head.midi,
                  channel: channelCount
                })
              }
            }
          } else {
            dispatch('triggerAttackRelease', {
              midi: bloc.head.midi,
              duration: Math.round(bloc.duration.time / tempoRatio)
            })
          }
          channelCount++
        }
      })
    }
    //
    // On stop
    //
    if (type === 'stop' && playing) {
      dispatch('releaseAll')
    }
  },

  // ===========================================================================

  async start({ commit, state }) {
    if (state.status != STATUS.off) return
    commit('setStatus', STATUS.starting)
    Tone = await import('tone')
    await Tone.start()
    const urls = {}
    for await (let p of NOTES) {
      const module = await p.module
      urls[p.note] = module.default
    }
    return new Promise((resolve, reject) => {
      sampler = new Tone.Sampler({
        urls,
        release: 1,
        onload: () => {
          commit('setStatus', STATUS.started)
          console.log('SYNTH STARTED')
          resolve()
        },
        onerror: (error) => {
          reject(error)
        }
      }).toDestination()
      sampler.volume.value = clampVolume(state.settings.volume)
    })
  },

  // ===========================================================================

  triggerAttack({ state, commit, dispatch }, { midi, channel, id }) {
    const current = state.channels[channel]
    dispatch('triggerRelease', channel)
    const name = midi.pitch + midi.accidental + midi.octava
    if (!current || id != current.id) {
      try {
        sampler.triggerAttack(name)
      } catch (e) {
        console.warn(`WTF ! ${name} is not a valid note`)
      }
      commit('channels', { channel, name, id })
    }
  },

  // ===========================================================================

  triggerRelease({ commit }, { channel }) {
    if (state.channels[channel]) {
      sampler.triggerRelease(state.channels[channel])
      commit('channels', { channel, name: null })
    }
  },

  // ===========================================================================

  triggerAttackRelease(context, { midi, duration }) {
    if (midi) {
      const name = midi.pitch + midi.accidental + midi.octava
      sampler.triggerAttackRelease(name, duration)
    }
  },

  // ===========================================================================

  releaseAll({ commit }) {
    if (!sampler) return
    sampler.releaseAll()
    commit('channels', {})
  }
}

export default { namespaced: true, getters, state, mutations, actions }
