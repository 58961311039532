import Vue from 'vue'
import * as firebase from '@firebase/app'
import * as auth from '@firebase/auth'
import { getAnalytics } from '@firebase/analytics'

import 'firebaseui/dist/firebaseui.css'

// import '@firebase/firestore'
// import '@firebase/functions'
// import '@firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyCAhSCWeQfPE2rhMWK_cU93G6vZdnUS3m8',
  authDomain: 'songjelly-3f910.firebaseapp.com',
  projectId: 'songjelly-3f910',
  storageBucket: 'songjelly-3f910.appspot.com',
  messagingSenderId: '883380491224',
  appId: '1:883380491224:web:080b3570278b700be0d8ad',
  measurementId: 'G-ZTLSY8SHH0'
}

// Get a Firestore instance
const app = firebase.initializeApp(firebaseConfig)

// db.settings({
//   cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
// })

Vue.prototype.$firebase = { app, auth }

getAnalytics()

export default app
