import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'sm' // This is equivalent to a value of 960
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#6c6c6c',
        accent: '#e98100',
        info: '#a7aaab'
      },
      dark: {
        primary: '#FFFFFF',
        accent: '#e98100',
        info: '#38474a'
      }
    }
  }
})
