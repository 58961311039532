<template>
  <div class="score-drawer">
    <v-btn-toggle class="drawer-bar" v-model="buttonGroup" tile borderless>
      <bar-button icon="guitar-diagram" />
      <bar-button icon="piano" />
    </v-btn-toggle>

    <!-- <div class="progress" :style="`transform:scaleX(${chordProgress})`" /> -->

    <diagrams :type="diagram" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

import BarButton from '../button/BarButton.vue'
import Diagrams from '../score/Diagrams.vue'

export default {
  name: 'ScoreDrawer',
  components: {
    BarButton,
    Diagrams
  },

  data: () => ({
    buttonGroup: 0
  }),

  computed: {
    ...mapGetters('app', ['settings']),
    ...mapGetters('score', ['report', 'scoreSettings']),
    ...mapGetters('player', ['headEvent', 'progress']),

    chordProgress() {
      const fromToNext = this.toTime - this.fromTime
      const fromToHead = this.progress - this.fromTime
      return fromToNext && fromToHead ? fromToHead / fromToNext : 0
    },

    diagram() {
      return this.settings.info.panel === 0 ? 'guitar' : 'piano'
    }
  },
  watch: {
    buttonGroup(value) {
      this.setSettings(['info/panel', value])
    },
    'settings.info.panel': {
      handler(value) {
        return (this.buttonGroup = value)
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('app', ['setSettings'])
  }
}
</script>

<style scoped>
.score-drawer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* PANEL BAR */

.drawer-bar {
  border-bottom: solid 1px var(--app-color-line);
}
.v-btn-toggle > .bar-button.v-btn {
  flex: 1;
  max-width: initial !important;
  background-color: var(--app-color-layout) !important;
  opacity: 1;
}
.v-btn /deep/ .app-icon {
  opacity: 0.7;
}
/* .v-item--active /deep/ .app-icon {
  opacity: 0.7;
} */
.bar-button:first-child {
  border-right: solid 1px var(--app-color-line) !important;
}
.bar-button:before {
  /* height: 3px; */
  opacity: 0 !important;
}

/* .chords {
  font-family: Palanquin;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
}

.progress {
  min-height: 2px;
  background: var(--v-accent-base);
  width: 100%;
  transform-origin: left;
  padding: 0;
  flex: 0;
}

.chord {
  background: #00000011;
  border-radius: 2px;
  font-size: 20px;
  font-weight: 700;
  padding: 0 8px;
}

.arrow {
  color: #00000050 !important;
  margin: 0 16px;
} */

.diagrams {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: var(--app-gutter-md);
}

.separator {
  width: 10px;
  margin-top: var(--app-gutter-md);
  margin-bottom: var(--app-gutter-sm);
  border-bottom: solid 1px #00000050;
}
</style>
