<template>
  <tile-button
    class="bar-button"
    v-bind="$attrs"
    v-on="$listeners"
    :icon="icon"
    :to="to"
    :large="large"
    :loading="loading"
    :active="active"
    @click="clickHandler"
  >
    <slot />
  </tile-button>
</template>

<script>
import TileButton from '../button/TileButton.vue'
export default {
  name: 'BarButton',
  components: { TileButton },
  props: {
    to: String,
    disabled: Boolean,
    trigger: Function,
    icon: String,
    large: Boolean,
    loading: Boolean,
    active: Boolean
  },
  methods: {
    clickHandler: function () {
      const slotItem = this.$slots.default && this.$slots.default[0]
      if (slotItem) {
        const tagName = slotItem.componentOptions?.tag
        if (tagName?.endsWith('dialog')) {
          const dialog = slotItem.child
          setTimeout(dialog.open, 10)
        } else if (tagName?.endsWith('menu')) {
          const dialog = slotItem.componentInstance
          // subbarActivator is auto given by Fluidbar
          const activator = this.subbarActivator || this
          setTimeout(dialog.open(activator), 10)
        }
      } else if (this.trigger) this.trigger()
    }
  }
}
</script>
<style scoped></style>
