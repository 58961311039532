<template>
  <div class="search-bar">
    <v-text-field
      id="searchField"
      solo
      dense
      hide-details
      flat
      clearable
      placeholder="What would you search ?"
      @input="search($event)"
      @keyup.enter="gotoSearch($event)"
      v-model="searchValue"
      autocomplete="off"
    />
    <v-menu
      v-if="hasResult || isSearching"
      v-model="isOpen"
      activator="#searchField"
      :open-on-click="false"
      :open-on-focus="hasResult && !isEmpty"
      offset-y
    >
      <v-list dense>
        <v-list-item class="no-result" v-if="isSearching">
          Let me check...
        </v-list-item>
        <v-list-item class="no-result" v-else-if="isEmpty">
          I've got nothing for you. Try something else :)
        </v-list-item>

        <template v-else v-for="(item, index) in searchResult">
          <v-subheader v-if="item.header" :key="`header-${index}`">{{
            item.header
          }}</v-subheader>
          <drive-item
            :item="item"
            :key="`content-${index}`"
            @click="clearSearch"
          />
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import searchMixin from '../../mixins/searchMixin'
import DriveItem from '../misc/DriveItem.vue'
export default {
  name: 'LibraryBar',
  mixins: [searchMixin],
  components: { DriveItem },
  data: () => ({
    searchValue: '',
    isOpen: false,
    type: 'quick'
  }),
  created() {
    const search = this.search
    this.search = async (searchValue) => {
      this.isOpen = Boolean(searchValue)
      await search(searchValue)
    }
  },
  methods: {
    getIcon(item) {
      return item.type === 'file' ? 'music-box-outline' : 'folder'
    },

    gotoSearch(event) {
      const searchValue = event.target.value
      if (searchValue) {
        this.$router.push('/search/' + searchValue)
        this.isOpen = false
        event.target.blur()
      }
    },

    clearSearch() {
      this.searchValue = ''
      this.debouncedSearch({ query: null, type: 'quick' })
    }
  }
}
</script>

<style scoped>
.search-bar {
  display: inline-flex;
  width: 100%;
  background: var(--app-color-layout);
  align-items: center;
  height: 49px;
  z-index: 3;
}
.no-result {
  font-style: italic;
  opacity: 0.5;
}
</style>
