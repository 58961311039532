<template>
  <v-container fluid class="fill-height">
    <v-layout align-center justify-center class="flex-column">
      <v-progress-circular
        v-show="visible"
        size="80"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <slot />
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'AppSpinner',
  props: {
    delay: { type: Number, default: 0 }
  },
  data: () => ({
    visible: false
  }),
  mounted() {
    setTimeout(() => {
      this.visible = true
    }, this.delay)
  }
}
</script>

<style></style>
