import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './components/view/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/auth',
    name: 'auth',
    component: () =>
      import(/* webpackChunkName: "auth" */ './components/view/Auth.vue')
  },
  {
    path: '/doc/:docPath',
    props: true,
    meta: {
      fillHeight: true
    },
    component: () =>
      import(/* webpackChunkName: "doc" */ './components/view/Doc.vue')
  },
  {
    path: '/doc',
    props: { docPath: '' },
    meta: {
      fillHeight: true
    },
    component: () =>
      import(/* webpackChunkName: "doc" */ './components/view/Doc.vue')
  },
  {
    path: '/score/:contextId/:driveId/:scoreId',
    name: 'score-context',
    props: true,
    meta: {
      fillHeight: true,
      hasDrawer: true,
      authRequired: (to) => to.params.driveId === 'remote'
    },
    component: () =>
      import(
        /* webpackChunkName: "score-player" */ './components/view/Score.vue'
      )
  },
  {
    path: '/score/:driveId/:scoreId',
    name: 'score',
    props: true,
    meta: {
      fillHeight: true,
      hasDrawer: true,
      authRequired: (to) => to.params.driveId === 'remote'
    },
    component: () =>
      import(
        /* webpackChunkName: "score-player" */ './components/view/Score.vue'
      )
  },
  {
    path: '/drive/:driveId/:folderId',
    name: 'drive-folder',
    props: true,
    meta: {
      fillHeight: true,
      authRequired: (to) => to.params.driveId === 'remote'
    },
    component: () =>
      import(/*webpackChunkName: "user-score"*/ './components/view/Drive.vue')
  },
  {
    path: '/drive/:driveId',
    name: 'drive-root',
    props: true,
    meta: {
      fillHeight: true,
      authRequired: (to) => to.params.driveId === 'remote'
    },
    component: () =>
      import(/* webpackChunkName: "user-score" */ './components/view/Drive.vue')
  },
  {
    path: '/drive',
    name: 'drive',
    meta: {
      fillHeight: true
    },
    component: () =>
      import(/* webpackChunkName: "user-score" */ './components/view/Drive.vue')
  },
  {
    path: '/peer/:remoteId',
    name: 'peer-remote',
    props: true,
    meta: { fillHeight: true, hasDrawer: true },
    component: () =>
      import(/* webpackChunkName: "user-score" */ './components/view/Peer.vue')
  },
  {
    path: '/peer',
    name: 'peer',
    meta: { fillHeight: true, hasDrawer: true },
    component: () =>
      import(/* webpackChunkName: "user-score" */ './components/view/Peer.vue')
  },
  {
    path: '/search/:searchValue',
    name: 'search-value',
    props: true,
    meta: {
      fillHeight: true,
      authRequired: true
    },
    component: () =>
      import(/* webpackChunkName: "search" */ './components/view/Search.vue')
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      fillHeight: true,
      authRequired: true
    },
    component: () =>
      import(/* webpackChunkName: "search" */ './components/view/Search.vue')
  }
  // {
  //   path: '/profil',
  //   name: 'user-profil',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "user-profil" */ '../components/view/Profil.vue'
  //     )
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../components/view/About.vue')
  // },
  // {
  //   path: '*',
  //   name: 'fallback',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../components/view/Fallback.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
