<template>
  <v-btn
    v-bind="$attrs"
    :class="buttonClass"
    v-on="$listeners"
    tile
    elevation="0"
    :to="to"
    :loading="loading"
  >
    <app-icon :value="icon" />
    <slot />
  </v-btn>
</template>

<script>
import AppIcon from '../misc/AppIcon.vue'

export default {
  name: 'TileButton',
  components: {
    AppIcon
  },
  props: {
    icon: String,
    large: Boolean,
    to: String,
    loading: Boolean,
    active: Boolean
  },
  computed: {
    buttonClass() {
      const cls = ['tile-button']
      if (this.large) cls.push('large')
      if (this.active) cls.push('active')
      return cls
    }
  }
  // methods: {
  //   clickHandler() {
  //     this.$emit('click')
  //   }
  // }
}
</script>
<style scoped>
.tile-button {
  /* background: var(--app-color-paper) !important; */
  min-width: 48px !important;
  min-height: 48px !important;
  max-width: 48px !important;
  max-height: 48px !important;
  position: relative;
}
.tile-button.large.v-btn {
  min-width: 64px !important;
  min-height: 64px !important;
}

/* INTO FLUID BAR */
.divider {
  box-sizing: border-box;
  border-right: solid 1px var(--app-color-line);
}
.divider.before {
  border-left: solid 1px var(--app-color-line);
  border-right: none;
}
.vertical-bar .divider {
  border-bottom: solid 1px var(--app-color-line);
  border-right: none;
  border-left: none;
}
.vertical-bar .divider.before {
  border-top: solid 1px var(--app-color-line);
  border-bottom: none;
}
/* INTO BAR MENU */

.subbar .tile-button {
  background-color: var(--app-color-paper) !important;
}

.tile-button.active:before {
  /* background: var(--v-accent-base); */
  opacity: 0.1;
  margin: 6px;
  border-radius: 48px;
}
</style>
