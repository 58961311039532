import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import vuetify from './vuetify.js'
import firebaseApp from './firebase.js'
import store from './store.js'

import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents)

import _codemirror from 'codemirror'

window.CodeMirror = _codemirror

Vue.config.productionTip = false

store.dispatch('auth/initialize', { router })

new Vue({
  router,
  vuetify,
  store,
  firebaseApp,
  render: (h) => h(App)
}).$mount('#app')
