<template>
  <common-dialog ref="dialog" no-action width="400px">
    <div class="view" v-if="isOff || isConnecting">
      <div>Open broadcasted score from another devices</div>
      <v-text-field
        v-if="!remoteIsLocked"
        label="Identifer of the broadcast"
        v-model="remoteId"
        persistent-placeholder
        hide-details
      />
      <v-text-field
        label="Your name on network"
        v-model="userLabel"
        placeholder=" "
        persistent-placeholder
        hide-details
      />
      <v-btn
        color="accent"
        @click="connect()"
        :disabled="!remoteId || !userLabel"
        :loading="isConnecting"
      >
        Let's go !
      </v-btn>
    </div>

    <div class="view with-error" v-else-if="isError">
      <div class="error-message">
        Oups, something goes wrong ! The connection failed for the reason below.
      </div>
      <div class="error-type">{{ peerError.message }}</div>
      <v-btn color="accent" @click="peerClear()">Retry</v-btn>
    </div>

    <div class="view" v-else>
      <div>You are connected to the channel {{ peerRemoteId }}</div>
      <v-btn color="accent" @click="peerLeave()">Leave</v-btn>
    </div>
  </common-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CommonDialog from './CommonDialog.vue'
export default {
  name: 'Peer',
  components: { CommonDialog },
  data: () => ({
    remoteId: '',
    remoteIsLocked: false,
    userLabel: ''
  }),
  computed: {
    ...mapGetters('app', ['settings']),
    ...mapGetters('peer', ['peerStatus', 'peerError', 'peerRemoteId']),

    isOff() {
      return this.peerStatus === 'off'
    },
    isConnecting() {
      return this.peerStatus === 'connecting'
    },

    isError() {
      return this.peerStatus === 'error'
    }
  },
  methods: {
    ...mapActions('app', ['setSettings']),
    ...mapActions('peer', ['peerConnect', 'peerClear', 'peerLeave']),

    open(clear) {
      const route = this.$route
      this.remoteId = route.params.remoteId || ''
      this.remoteIsLocked = route.params.remoteId
      this.userLabel = this.settings.peer.peerLabel || ''
      if (clear && this.isError) this.peerClear()
      this.$refs.dialog.open()
    },

    async connect() {
      if (this.userLabel) {
        await this.setSettings(['peer/peerLabel', this.userLabel])
      }
      await this.peerConnect(this.remoteId)
      if (!this.peerError) {
        this.$refs.dialog.close()
      }
    }
  }
}
</script>

<style scoped>
.view {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.view > *:not(:last-child) {
  margin-bottom: var(--app-gutter-sm);
}
.v-btn {
  align-self: center;
  margin-top: var(--app-gutter-md);
}
.v-text-field {
  align-self: stretch;
}
.error-message {
  font-weight: bold;
}
</style>
