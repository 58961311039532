export const getMinMultiple = (data) => {
  if (!data.length) return 1
  var maxOf = data.reduce((max, p) => (p > max ? p : max), 0)
  var incremental = maxOf
  var found = false
  let secure = 0
  do {
    for (var j = 0; j < data.length; j++) {
      if (maxOf % data[j] !== 0) {
        maxOf += incremental
        break
      } else {
        if (j === data.length - 1) {
          found = true
          break
        }
      }
    }
    if (secure++ > 10000) {
      throw new Error('Infinite loop : ' + data)
    }
  } while (!found)
  return maxOf
}

export const nextFrame = async () => {
  return new Promise((resolve) => {
    window.requestAnimationFrame(() => {
      resolve()
    })
  })
}

export const timeout = async (value) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, value)
  })
}

export const until = async (cb, max) => {
  if (!max) max = 1000
  let count
  return new Promise((resolve, reject) => {
    const loop = () => {
      window.requestAnimationFrame(() => {
        count++
        if (count++ > max) reject()
        if (cb()) resolve()
        else loop()
      })
    }
    loop()
  })
}

export const applyStyle = (stylesheet, className, vars) => {
  const cssRule = Array.from(stylesheet.cssRules).find(
    (e) => e.selectorText === className
  )
  if (!cssRule) {
    const ccsVars = []
    Object.keys(vars).forEach((varname) => {
      ccsVars.push(`--sheet-${varname} : ${vars[varname]}`)
    })
    stylesheet.insertRule(`.${className} {${ccsVars.join(';')}}`)
  }
}
