import Vue from 'vue'
import Vuex from 'vuex'

import app from './store/app-store'
import auth from './store/auth-store'
import drive from './store/drive-store'
import driveLocal from './store/drive-local-store'
import driveRemote from './store/drive-remote-store'
import score from './store/score-store'
import editor from './store/editor-store'
import player from './store//player-store'
import synth from './store//synth-store'
import info from './store/info-store'
import search from './store/search-store'
import metronome from './store//metronome-store'
import cast from './store/cast-store'
import peer from './store/peer-store'
import contact from './store/contact-store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    drive,
    driveLocal,
    driveRemote,
    score,
    editor,
    player,
    synth,
    info,
    search,
    metronome,
    cast,
    peer,
    contact
  }
})
