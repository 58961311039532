<template>
  <bar-menu>
    <v-list-item class="user-item">
      <v-avatar color="primary lighten-4" flex>
        <img :src="authUser.photoURL" v-if="authUser.photoURL" />
        <span v-else>{{ authUser.initials }}</span>
      </v-avatar>
      <div class="display-name">{{ authUser.displayName }}</div>
      <div class="email">{{ authUser.email }}</div>
    </v-list-item>
    <v-list-item class="button-item">
      <v-btn color="accent" @click="authLogout" block>Disconnect</v-btn>
    </v-list-item>
  </bar-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import menuMixin from '../../mixins/menu-mixin'
import BarMenu from '../menu/BarMenu.vue'

export default {
  name: 'AuthMenu',
  mixins: [menuMixin],
  components: { BarMenu },
  computed: {
    ...mapGetters('auth', ['authUser'])
  },
  methods: {
    ...mapActions('auth', ['authLogout'])
  }
}
</script>
<style scoped>
.user-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: initial;
  padding-top: var(--app-gutter-md);
}
.button-item {
  margin-bottom: var(--app-gutter-sm);
}

.display-name {
  margin-top: var(--app-gutter-md);
  font-weight: 600;
  font-size: large;
}
.email {
  margin-bottom: var(--app-gutter-md);
  font-size: medium;
  opacity: 0.7;
}
</style>
