import Peer from 'peerjs'
import { generateID } from '../utils/store-utils'

// Prefix use to build the peerId
export const PREFIX_ID = 'songjelly'
// Timeout for peer opening
const PEER_TIMEOUT = 5000
// Timeout for connection opening
const CONNECTION_TIMEOUT = 5000

export const STATUS = {
  off: 'off',
  connecting: 'connecting',
  on: 'on',
  error: 'error'
}

// -----------------------------------------------------------------------------

const callBackToPromise = (target, timeout) => {
  let promiseFinalized = false
  return new Promise((resolve, reject) => {
    //
    // Peer is connected
    //
    target.on('open', () => resolve())
    //
    // Peer has error during connection
    //
    target.on('error', (err) => reject(err))
    //
    // Peer timeout
    //
    setTimeout(() => {
      if (!promiseFinalized) {
        reject({
          type: 'peer-timeout',
          message: 'Timeout exceeded !'
        })
      }
    }, timeout)
  }).finally(() => {
    promiseFinalized = true
    target.removeAllListeners()
  })
}

// -----------------------------------------------------------------------------

export const openPeer = (peer, peerId) => {
  if (peer?.disconnected) {
    //
    // Reconnect a existing peer, if it has been disconnected
    //
    peer.reconnect()
  } else {
    //
    // Create a peer instance
    //
    if (!peerId) peerId = generateID()
    peer = new Peer(PREFIX_ID + '-' + peerId)
  }
  //
  // Set the callback
  //
  return callBackToPromise(peer, PEER_TIMEOUT).then(() => ({
    peer,
    peerId
  }))
}

// -----------------------------------------------------------------------------

export const openConnection = (peer, remoteId, label) => {
  //
  // Create the connection
  //
  remoteId = PREFIX_ID + '-' + remoteId
  const connection = peer.connect(remoteId, { label })
  //
  // Set the callback
  //
  return callBackToPromise(connection, CONNECTION_TIMEOUT).then(
    () => connection
  )
}
