import algoliasearch from 'algoliasearch'

// const resultsample = [
//   { index: 0, label: 'tutu', icon: 'score', header: 'history' },
//   { index: 8, label: 'toto', icon: 'score' },
//   { index: 1, label: 'score locale', icon: 'score', header: 'local' },
//   { index: 2, label: 'score 1 locale', icon: 'score' },
//   { index: 3, label: 'score 2 remote', icon: 'score', header: 'remote' },
//   { index: 4, label: 'score 3 remote', icon: 'score' },
//   { index: 5, label: 'score 4 remote', icon: 'score' },
//   { index: 6, label: 'folder 1 remote', icon: 'folder' },
//   { index: 7, label: 'Shared score', icon: 'score', header: 'shared' }
// ]

const client = algoliasearch('0VF5F6QRCV', 'e37577ed288e8509432c6b93d7bf03f9')

const state = {
  result: { quick: null, standard: null }
}

const getters = {
  searchResult: (state) => (type) => state.result[type]
}

const mutations = {
  setResult: (state, { result, type }) => {
    state.result[type] = result
  }
}

const actions = {
  setSettings({ commit }, { name, value }) {
    commit(name, value)
  },

  async search({ commit }, { query, type }) {
    if (!query) {
      commit('setResult', { type, result: null })
      return
    }
    const index = client.initIndex('SEARCH_DRIVE')

    const response = await index.search(query, {
      hitsPerPage: type === 'quick' ? 10 : 50
    })

    const result = response.hits.map((hit) => {
      const getHighlights = (h) => {
        const result = []
        for (const attr in h) {
          const o = h[attr]
          const matchLevel = o.matchLevel
          if (matchLevel) {
            if (matchLevel != 'none') {
              result.push({
                key: attr,
                value: o.value.split(',').join(', ').replace(/\s+/g, ' '),
                level: matchLevel
              })
            }
          } else {
            result.push(...getHighlights(o))
          }
        }
        return result
      }
      return {
        meta: hit.meta,
        path: `remote/${hit.objectID}`,
        isFile: hit.type === 'file',
        isFolder: hit.type === 'folder',
        tags: hit.tags,
        highlights: getHighlights(hit._highlightResult)
      }
    })
    if (result.length) {
      result[0].header = 'Remote'
    }
    console.log(result, response)

    commit('setResult', { type, result: result })
  }
}

export default { namespaced: true, getters, state, mutations, actions }
