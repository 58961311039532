import { mapActions, mapGetters } from 'vuex'

export default {
  props: { landscape: Boolean },
  computed: {
    ...mapGetters('app', ['settings']),
    ...mapGetters('score', ['score', 'scoreSettings', 'report']),

    isEditor() {
      return this.$route.params.contextId === 'editor'
    }
  },
  methods: {
    ...mapActions('app', ['setSettings']),
    ...mapActions('score', ['setScoreSettings']),

    open(activator) {
      this.$children[0].open(activator)
    }
  }
}
