<template>
  <v-menu
    class="bar-menu"
    :activator="activator"
    v-model="opened"
    :close-on-content-click="autoclose"
    :offset-x="landscape"
    :offset-y="!landscape"
  >
    <v-list :class="listClass">
      <slot />
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: 'BarMenu',
  props: {
    landscape: Boolean,
    icon: String,
    trigger: Function,
    autoclose: Boolean,
    autosize: Boolean
  },
  data: () => ({
    opened: false,
    activator: null
  }),
  computed: {
    layout() {
      return this.landscape ? 'vertical' : 'horizontal'
    },
    listClass() {
      var cls = ['subbar', this.layout, 'pa-0']
      if (this.autosize) cls.push('autosize')
      return cls
    }
  },
  watch: {
    opened(val) {
      if (!val) {
        this.activator = '#dsdfsdf'
      }
    }
  },
  methods: {
    open(activator) {
      this.activator = activator
      this.opened = true
    },
    hide() {
      this.opened = false
    }
  }
}
</script>
<style scoped>
.v-list {
  border-radius: 4px;
}
.v-list:not(.autosize) {
  /* background-color: initial; */
  min-width: 200px;
}
/* .v-list-item {
  border-top: solid 1px var(--app-color-line);
}
.activator-item::before {
  opacity: 0 !important;
}
.v-input--switch /deep/ .v-input--switch__thumb {
  color: var(--app-color-layout);
} */
</style>
