<template>
  <v-app-bar app elevation="0" v-if="!viewIsHome" clipped-right>
    <cast-dialog ref="castDialog" />
    <peer-dialog ref="peerDialog" />

    <v-toolbar-title class="title">
      <v-btn tile class="app-title" text to="/">{{ appTitle }}</v-btn>
    </v-toolbar-title>

    <search-bar v-if="authStatus === 'connected'" />
    <v-spacer v-else />

    <!-- PEER DIALOG -->
    <v-badge
      v-if="viewIsPeer"
      :value="peerStatus != 'off'"
      :offset-x="18"
      :offset-y="20"
      :color="statusColor[peerStatus]"
      dot
      overlap
    >
      <bar-button icon="access-point" :trigger="openPeerDialog" />
    </v-badge>

    <!-- CAST DIALOG -->
    <v-badge
      v-else-if="showCast"
      :content="castPeers.length"
      :value="castStatus != 'off'"
      :offset-x="castPeers.length ? 20 : 18"
      :offset-y="castPeers.length ? 25 : 20"
      :color="statusColor[castStatus]"
      :dot="!castPeers.length"
      overlap
    >
      <bar-button icon="cast" :trigger="openCastDialog" />
    </v-badge>

    <!-- TOGGLE FULLSCREEN -->
    <bar-button :icon="fullscreenIcon" :trigger="toggleFullscreen" />

    <!-- TOGGLE DRAWER -->
    <!-- <bar-button v-if="showDrawer" :icon="drawerIcon" :trigger="toggleDrawer" /> -->

    <!-- USER -->

    <bar-button icon="account-circle-outline" v-if="showUserAccount" bloc>
      <account-menu />
    </bar-button>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import BarButton from '../button/BarButton.vue'
import CastDialog from '../dialog/CastDialog.vue'
import PeerDialog from '../dialog/PeerDialog.vue'
import AccountMenu from '../menu/AccountMenu.vue'
import SearchBar from '../toolbar/SearchBar.vue'

export default {
  name: 'AppToolbar',
  components: { BarButton, CastDialog, PeerDialog, AccountMenu, SearchBar },

  data: () => ({
    isFullscreen: false,
    statusColor: {
      connecting: 'warning',
      on: 'success',
      error: 'error'
    }
  }),

  computed: {
    ...mapGetters('app', ['drawers']),
    ...mapGetters('cast', ['castStatus', 'castPeers']),
    ...mapGetters('peer', ['peerStatus']),
    ...mapGetters('auth', ['authStatus']),

    peerClass() {
      return `peer-button ${this.peerStatus}`
    },
    viewIsHome() {
      return this.$route?.name === 'home'
    },
    viewIsScore() {
      return this.$route?.name?.startsWith('score')
    },
    viewIsDrive() {
      return this.$route?.name?.startsWith('drive')
    },
    viewIsPeer() {
      return this.$route?.name?.startsWith('peer')
    },
    appTitle() {
      return 'SongJelly'
    },
    drawerIcon() {
      return this.drawers[1] ? 'hide-right-drawer' : 'show-right-drawer'
    },
    fullscreenIcon() {
      return this.isFullscreen ? 'fullscreen-exit' : 'fullscreen'
    },

    showPeer() {
      return this.viewIsPeer
    },
    showCast() {
      return this.viewIsDrive || this.viewIsScore
    },
    // showDrawer() {
    //   return this.$route.meta?.hasDrawer
    // },
    showUserAccount() {
      return this.authStatus === 'connected'
    }
  },

  mounted() {
    document.addEventListener('fullscreenchange', this.fullscreenChange)
    document.addEventListener('fullscreenerror', this.fullscreenError)
  },
  destroyed() {
    document.removeEventListener('fullscreenchange', this.fullscreenChange)
    document.removeEventListener('fullscreenerror', this.fullscreenError)
  },

  methods: {
    ...mapActions('app', ['setSettings']),

    openCastDialog() {
      this.$refs.castDialog.open()
    },

    openPeerDialog() {
      this.$refs.peerDialog.open()
    },

    toggleDrawer() {
      //
      // We save only if not mobile breakpoint
      //
      this.setSettings(['app/drawers', !this.drawers[1], 1])
    },

    toggleFullscreen() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen()
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        }
      }
    },

    fullscreenChange() {
      this.isFullscreen = document.fullscreenElement
    },

    fullscreenError() {
      this.isFullscreen = false
    }
  }
}
</script>

<style scoped>
.v-app-bar {
  background-color: var(--app-color-layout) !important;
  border-color: var(--app-color-line) !important;
  border-bottom: solid 1px;
  transition: background-color 0s !important;
}
.v-app-bar /deep/ .v-toolbar__content {
  padding: 0;
  /* padding-right: var(--app-gutter-sm); */
}

.title {
  display: flex;
  justify-content: center;
  align-items: baseline;
  overflow: initial;
}

.app-title {
  padding: 0 var(--app-gutter-md) !important;
  font-family: 'Pacifico', cursive;
  font-size: 20px !important;
  text-transform: initial;
  letter-spacing: initial;
}
.v-btn {
  margin-top: -2px;
  min-height: 56px !important;
}

.search-bar {
  margin-right: var(--app-gutter-md);
  margin-left: var(--app-gutter-md);
}

.app-button:last-child {
  margin-right: var(--app-gutter-sm) !important;
}
.cast-button {
  position: relative;
}
.v-badge:not(.v-badge--dot) /deep/ .v-badge__badge {
  font-size: 10px;
  height: 15px;
  letter-spacing: 0;
  line-height: 0.8;
  min-width: 15px;
  padding: 4px 4px;
}
.v-badge /deep/ .error {
  margin: 0;
  display: inline-flex;
}
</style>
