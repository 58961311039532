import { openPeer, STATUS, PREFIX_ID } from '../utils/peer-utils'

const PING_TIMEOUT = 10000

let _peer

const state = {
  error: null,
  status: STATUS.off,
  peers: [],
  //
  // Settings
  //
  settings: {
    castId: null,
    castLabel: ''
  }
}

// =============================================================================

const getters = {
  castError: (state) => state.error,
  castStatus: (state) => state.status,
  castPeers: (state) => state.peers,
  //
  // Settings
  //
  castId: (state) => state.settings.castId,
  castLabel: (state) => state.settings.castLabel
}

// =============================================================================

const mutations = {
  setStatus(state, value) {
    state.status = value
  },
  setError(state, value) {
    if (!value) {
      state.error = null
      state.status = STATUS.off
    } else {
      state.error = {
        message: value.message,
        type: value.type
      }
      state.status = STATUS.error
      if (_peer && !_peer.disconnected) {
        _peer.disconnect()
      }
    }
  },
  addPeer(state, connection) {
    state.peers.push(connection)
  },

  removePeer(state, index) {
    state.peers.splice(index, 1)
  },
  clearPeers(state) {
    state.peers.length = 0
  },
  //
  // Settings
  //
  castId: (state, value) => (state.settings.castId = value),
  castLabel: (state, value) => (state.settings.castLabel = value)
}

const actions = {
  setSettings({ commit }, { name, value }) {
    commit(name, value)
  },

  // ===========================================================================

  async on(ctx, details) {
    let message
    switch (details.store) {
      case 'score':
        if (details.score) {
          message = { type: 'score', ...details.score.toPeer() }
        }
        break
      case 'player':
        message = {
          type: 'play',
          start: details.start?.getTime() || null,
          progress: details.progress,
          playing: details.playing,
          delay: details.delay
        }
        break
    }
    if (message) {
      state.peers.forEach((peer) => {
        const connection = peer.connection()
        connection.send(message)
      })
    }
  },

  // ===========================================================================

  async castConnect({ state, commit, dispatch, rootGetters }, castName) {
    const isNewPeer = !_peer
    //
    // Check status
    //
    if (state.status === STATUS.connecting) {
      console.warn('WTF ! Peer is already trying to connect')
      return
    }
    if (state.status === STATUS.on) {
      console.warn('WTF ! Peer is already connected')
      return
    }
    //
    // Clear errors
    //
    commit('setStatus', STATUS.connecting)
    //
    // Waiting for callback
    //
    await openPeer(_peer, castName)
      .then(({ peer, peerId: castId }) => {
        if (_peer != peer) {
          _peer = peer
          console.log('PEER CREATED ', castId)
        } else {
          console.log('PEER RECONNECTED ', castId)
        }
        if (castId != state.settings.castId) {
          dispatch('app/setSettings', ['cast/castId', castId], {
            root: true
          })
        }
        commit('setStatus', STATUS.on)
      })
      .catch((err) => {
        commit('setError', err)
      })
    if (state.status === STATUS.error) return
    //
    // Add listener for subscriber peers
    //
    _peer.on('connection', function (connection) {
      const id = connection.peer.slice(PREFIX_ID.length + 1)
      //
      // Find if the client exists. In cast of disconnect/reconnect before next
      // ping. In this case the old connection is closed
      //
      let clientIndex = state.peers.findIndex((peer) => peer.id === id)
      if (clientIndex > -1) {
        const connection = state.peers[clientIndex].connection()
        connection.send({ type: 'close' })
        connection.close()
        commit('removePeer', clientIndex)
      }
      //
      // Create a new client. Note that the client can exist
      //
      const client = {
        ping: true,
        id,
        label: connection.label,
        connection: () => connection
      }
      commit('addPeer', client)

      //
      // Data received by client
      //
      connection.on('data', function (data) {
        switch (data.type) {
          case 'init': {
            const score = rootGetters['score/score']
            connection.send({ type: 'init', ...score.toPeer() })
            client.ping = true
            break
          }
          case 'ping':
            connection.send({ type: 'ping' })
            client.ping = true
            break
          case 'leave':
            commit(
              'removePeer',
              state.peers.findIndex((peer) => peer.id === id)
            )
            break
          default:
            console.log(`PEER ON ${connection.peer}`, data)
        }
      })
    })
    //
    // Start loop to check ping
    //
    if (isNewPeer) {
      const loop = (firstLoop) => {
        if (!firstLoop) {
          state.peers.forEach((peer) => {
            if (!peer.ping) {
              dispatch('castDisconnectPeer', peer.id)
            }
            peer.ping = false
          })
        }
        setTimeout(loop, PING_TIMEOUT)
      }
      loop(true)
    }
  },

  castClose({ state, commit }) {
    if (!_peer) {
      console.warn('WTF ! No opened peer to close')
    }
    //
    // Close
    //
    state.peers.forEach((peer) => {
      const connection = peer.connection()
      connection.send({ type: 'close' })
      // connection.close()
    })
    commit('clearPeers')
    commit('setError')
    _peer.disconnect()
    console.log('PEER CLOSED')
  },

  castDisconnectPeer({ state, commit }, peerId) {
    const peerIndex = state.peers.findIndex((peer) => peer.id === peerId)
    const peer = state.peers[peerIndex]
    if (!peer) {
      console.warn('WTF ! No subscriber to disconnected')
    }
    const connection = peer.connection()
    connection.send({ type: 'disconnect' })
    commit('removePeer', peerIndex)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
