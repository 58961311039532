// import deburr from 'lodash/deburr'
const indexedDB =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB

const DB_NAME = 'scorejelly-database'
const DB_VERSION = 3

let db, verbose

function getObjectStore(storeName, mode) {
  var tx = db.transaction(storeName, mode)
  return tx.objectStore(storeName)
}

const actions = {
  // ===========================================================================

  openDb: () => {
    return new Promise((resolve, reject) => {
      var req = indexedDB.open(DB_NAME, DB_VERSION)
      req.onsuccess = function () {
        db = this.result
        resolve(db)
        if (verbose) console.log('DATABASE OPEN', db.name)
      }
      req.onerror = function (event) {
        reject({ type: 'db-open-error:', code: event.target.errorCode })
      }
      req.onupgradeneeded = function () {
        var db = req.result
        if (!db.objectStoreNames.contains('drive')) {
          db.createObjectStore('drive', { keyPath: 'id' }).createIndex(
            'parent',
            'parent',
            { unique: false }
          )
        }
        if (!db.objectStoreNames.contains('scores')) {
          db.createObjectStore('scores', { keyPath: 'id' }).createIndex(
            'file',
            'file',
            { unique: false }
          )
        }
        if (!db.objectStoreNames.contains('settings')) {
          db.createObjectStore('settings', { keyPath: 'id' })
        }
        console.warn('DATABASE UPGRADE')
      }
    })
  },

  // ===========================================================================

  dbAdd: (context, { storeName, obj }) => {
    return new Promise((resolve, reject) => {
      var store = getObjectStore(storeName, 'readwrite')
      let req
      try {
        req = store.add(obj)
      } catch (error) {
        reject({ type: 'db-add-error:', error })
      }
      req.onsuccess = function (event) {
        if (verbose) console.log('DATABASE ADD', event.target.result)
        resolve(event.target.result)
      }
      req.onerror = function () {
        reject({ type: 'db-add-error:', error: this.error })
      }
    })
  },

  // ===========================================================================

  dbGetAll: (context, { storeName }) => {
    return new Promise((resolve, reject) => {
      const store = getObjectStore(storeName)
      const req = store.getAll()
      req.onsuccess = function (event) {
        if (verbose) console.log('DATABASE GET All', event.target.result)
        resolve(event.target.result)
      }
      req.onerror = function (event) {
        reject('db-getall-error:', event.target.errorCode)
      }
    })
  },

  // ===========================================================================

  dbGet: (context, { storeName, key }) => {
    return new Promise((resolve, reject) => {
      const store = getObjectStore(storeName)
      const req = store.get(key)
      req.onsuccess = function (event) {
        if (verbose) console.log('DATABASE GET', event.target.result)
        resolve(event.target.result)
      }
      req.onerror = function (event) {
        reject('db-get-error:', event.target.errorCode)
      }
    })
  },

  // ===========================================================================

  dbIndex: (context, { storeName, indexName, indexValue }) => {
    return new Promise((resolve, reject) => {
      const result = []
      const store = getObjectStore(storeName)
      const index = store.index(indexName)
      const range = IDBKeyRange.only(indexValue)
      const req = index.openCursor(range)
      req.onsuccess = function (event) {
        var cursor = event.target.result
        if (cursor) {
          result.push(cursor.value)
          cursor.continue()
        } else {
          if (verbose) console.log('DATABASE GET', result)
          resolve(result)
        }
      }
      req.onerror = function (event) {
        reject('db-get-error:', event.target.errorCode)
      }
    })
  },

  // ===========================================================================

  dbSet: (context, { storeName, key, property, value }) => {
    return new Promise((resolve, reject) => {
      var store = getObjectStore(storeName, 'readwrite')
      var request = store.get(key)
      request.onsuccess = function () {
        var data = this.result
        data[property] = value
        var requestUpdate = store.put(data)
        requestUpdate.onsuccess = function () {
          resolve()
        }
        requestUpdate.onerror = function (event) {
          reject('db-get-error:', event.target.errorCode)
        }
      }
      request.onerror = function (event) {
        reject('db-get-error:', event.target.errorCode)
      }
    })
  },

  // ===========================================================================

  dbDelRecurse: async ({ dispatch }, { key }) => {
    const recurse = async (key) => {
      let items = await dispatch('dbIndex', {
        storeName: 'drive',
        indexName: 'parent',
        indexValue: key
      })
      for await (let item of items) {
        await recurse(item.id)
      }
      await dispatch('dbDel', { storeName: 'drive', key })
      try {
        await dispatch('dbDel', { storeName: 'scores', key })
      } catch (e) {
        // Do nothing
      }
    }
    await recurse(key)
  },

  // ===========================================================================

  dbDel: (context, { storeName, key }) => {
    return new Promise((resolve, reject) => {
      const store = getObjectStore(storeName, 'readwrite')
      const req = store.delete(key)
      req.onsuccess = resolve
      req.onerror = function (event) {
        reject('db-del-error:', event.target.errorCode)
      }
    })
  },

  // ===========================================================================

  dbSearch: (context, { searchValue }) => {
    return new Promise((resolve) => {
      var transaction = db.transaction('drive')
      var objectStore = transaction.objectStore('drive')
      var request = objectStore.openCursor()
      request.onsuccess = function (event) {
        var cursor = event.target.result
        if (cursor) {
          console.log(cursor.value, searchValue)
          cursor.continue()
        } else {
          resolve()
        }
      }
    })
  },

  // ===========================================================================

  /**
   * Export all data from an IndexedDB database
   *
   * https://gist.github.com/loilo/ed43739361ec718129a15ae5d531095b
   */

  dbExport: (context, { indent }) => {
    return new Promise((resolve, reject) => {
      const exportObject = {}
      if (db.objectStoreNames.length === 0) {
        resolve(JSON.stringify(exportObject))
      } else {
        const transaction = db.transaction(db.objectStoreNames, 'readonly')
        transaction.onerror = (event) => {
          reject(event.target.error)
        }
        for (const storeName of db.objectStoreNames) {
          const allObjects = []
          transaction
            .objectStore(storeName)
            .openCursor()
            .addEventListener('success', (event) => {
              const cursor = event.target.result
              if (cursor) {
                // Cursor holds value, put it into store data
                allObjects.push(cursor.value)
                cursor.continue()
              } else {
                // No more values, store is done
                exportObject[storeName] = allObjects

                // Last store was handled
                if (
                  db.objectStoreNames.length ===
                  Object.keys(exportObject).length
                ) {
                  resolve(JSON.stringify(exportObject, null, indent))
                }
              }
            })
        }
      }
    })
  },

  // ===========================================================================

  /**
   * Import data from JSON into an IndexedDB database.
   * This does not delete any existing data from the database, so keys may clash.
   *
   * @param {string}      json        Data to import, one key per object store
   * @return {Promise<void>}
   */
  dbImport: (context, { json }) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(db.objectStoreNames, 'readwrite')
      transaction.onerror = (event) => {
        reject(event.target.error)
      }
      let importObject = typeof json === 'string' ? JSON.parse(json) : json
      for (const storeName of db.objectStoreNames) {
        let count = 0
        const store = importObject[storeName] || []
        for (const toAdd of store) {
          const request = transaction.objectStore(storeName).add(toAdd)
          request.onsuccess = () => {
            count++
            if (count === importObject[storeName].length) {
              // Added all objects for this store
              delete importObject[storeName]
              if (Object.keys(importObject).length === 0) {
                // Added all object stores
                resolve()
              }
            }
          }
        }
      }
    })
  },
  // ===========================================================================

  /**
   * Clear the database
   */

  dbClear: () => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(db.objectStoreNames, 'readwrite')
      let count = 0
      transaction.onerror = (event) => {
        reject(event.target.error)
      }
      for (const storeName of db.objectStoreNames) {
        const req = transaction.objectStore(storeName).clear()
        req.onsuccess = () => {
          count++
          if (count === db.objectStoreNames.length) {
            resolve()
          }
        }
      }
    })
  }
}

export default {
  namespaced: true,
  actions
}
