<template>
  <v-app dark>
    <!-- DIALOG -->
    <login-dialog ref="loginDialog" />

    <!-- STATS -->
    <div id="stats" v-show="showStats && !isHome" ref="stats"></div>
    <!-- SCORE EXTENDS -->

    <!-- SPINNER -->
    <app-spinner v-if="!initialized || authStatus === 'initializing'" />

    <div v-else class="fill-height" @contextmenu="onContextMenu($event)">
      <!--  DRAWER -->
      <right-drawer></right-drawer>

      <!-- TOOLBAR -->
      <app-bar id="appToolbar" v-if="!scoreExtends"></app-bar>

      <!-- ROUTER VIEW -->
      <v-main class="main fill-height">
        <v-container fluid :class="containerClass">
          <router-view class="view" />
        </v-container>
      </v-main>
    </div>

    <!-- SNACKBAR -->
    <v-snackbar
      v-if="notification"
      v-model="snackbar"
      timeout="3000"
      bottom
      :color="notification.color"
      app
    >
      {{ notification.label }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import Stats from 'stats.js'

import LoginDialog from './components/dialog/LoginDialog.vue'
import AppSpinner from './components/misc/AppSpinner.vue'
import AppBar from './components/toolbar/AppBar.vue'
import RightDrawer from './components/drawer/RightDrawer.vue'

export default {
  name: 'JellyApp',
  components: { AppBar, RightDrawer, AppSpinner, LoginDialog },
  data: () => ({
    snackbar: false,
    showStats: false
  }),
  computed: {
    ...mapGetters('app', [
      'initialized',
      'settings',
      'notification',
      'headerHidden'
    ]),
    ...mapGetters('score', ['scoreExtends']),
    ...mapGetters('auth', ['authStatus']),
    ...mapGetters('editor', ['preview']),

    isHome() {
      return this.$route?.name === 'home'
    },
    containerClass() {
      return this.$route.meta?.fillHeight ? 'fill-height' : ''
    }
  },
  watch: {
    notification() {
      if (this.snackbar) {
        setTimeout(function () {
          this.snackbar = true
        }, 500)
        this.snackbar = false
      } else {
        this.snackbar = true
      }
    }
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      if (from.name?.startsWith('score') && this.scoreExtends) {
        this.toggleExtends()
      }
      next()
    })
  },
  mounted() {
    if (this.showStats) {
      var stats = new Stats()
      this.$refs.stats.appendChild(stats.dom)
      const update = () => {
        stats.update()
        requestAnimationFrame(update)
      }
      update()
    }
    this.initialize()
  },
  methods: {
    ...mapActions('app', ['initialize']),
    ...mapMutations('app', ['setContextMenu']),
    ...mapMutations('score', ['toggleExtends']),

    onContextMenu(event) {
      // prevent browser context menu
      event.preventDefault()
      // close the current app context Menu
      this.setContextMenu()
    }
  }
}
</script>

<style lang="scss">
@import './scss/common.scss';

html {
  overflow: auto;
  user-select: none;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--app-color-scroll-thumb) var(--app-color-scroll-track);
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
*::-webkit-scrollbar-track {
  background: var(--app-color-scroll-track);
  opacity: 0;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--app-color-scroll-thumb);
  border-radius: 6px;
  border: 2px solid var(--app-color-scroll-track);
  border-left: 3px solid var(--app-color-scroll-track);
}

.v-list-item__content > *:not(:last-child) {
  // Why this bottom margin while the it's a flex horizontal display ?
  margin-bottom: 0px;
}

.v-label {
  color: currentColor !important;
  pointer-events: none;
}
.v-text-field input {
  padding: 4px 0 !important;
  margin: 4px 0px !important;
}
.v-icon {
  color: currentColor !important;
}

.v-menu__content {
  overflow-y: initial !important;
  contain: initial !important;
  overflow-x: initial !important;
}

.v-overlay {
  z-index: 7 !important;
}

// https://stackoverflow.com/questions/55131944/how-to-remove-blue-background-on-chrome-autocomplete

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}
</style>

<style>
.v-application {
  background: var(--app-color-background) !important;
  overflow: hidden;
}

.container {
  height: 100%;
  background: var(--app-color-background);
  padding: 0 !important;
  align-items: stretch !important;
}
.view {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.drawer {
  z-index: 7;
}

.auth-dialog {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000033;
}

#stats > div {
  left: 135px !important;
  top: 8px !important;
}
</style>
