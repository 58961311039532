<template>
  <v-list-item
    class="drive-item"
    link
    :to="getItemTo(item)"
    :two-line="subtitles.length > 0"
    :three-line="subtitles.length > 1"
  >
    <template>
      <v-list-item-icon>
        <app-icon :value="getItemIcon(item)" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-html="title" />
        <v-list-item-subtitle
          v-for="(subtitle, index) in subtitles"
          :key="index"
          v-html="subtitle"
        />
      </v-list-item-content>
      <v-list-item-content class="ml-2" style="flex: initial">
        <score-tags :tags="item.tags" />
      </v-list-item-content>
    </template>
  </v-list-item>
</template>

<script>
import AppIcon from './AppIcon.vue'
import ScoreTags from '../score/ScoreTags.vue'

export default {
  name: 'DriveItem',
  components: { AppIcon, ScoreTags },
  props: {
    item: Object
  },
  data: () => ({
    title: '',
    subtitles: []
  }),
  watch: {
    item: {
      handler(item) {
        if (!item) {
          // No item : reset value
          this.title = ''
          this.subtitles = []
          return
        }
        // const meta = this.item.meta
        // const name = this.item.name
        const highlights = this.item.highlights
        const roles = { performer: 0, composer: 1, lyricist: 2 }
        const meta = this.item.meta
        let title = meta.title
        // let defautPeople = true
        let mainPeople = meta.performer || meta.composer || meta.lyricist
        const people = []
        const metas = []
        const tags = []
        highlights.forEach((highlight) => {
          const key = highlight.key
          const value = highlight.value
          if (key === 'title') {
            title = highlight.value
          } else if (key === 'tags') {
            tags.push(value)
          } else if (!isNaN(roles[key])) {
            people.push(highlight)
          } else {
            metas.push(`${key}: ${value}`)
          }
        })

        people.sort((a, b) => roles[a.key] - roles[b.key])

        if (people.length) {
          mainPeople = people[0].value
          people.shift()
          title = `${mainPeople} - ${title}`
        } else if (mainPeople) {
          title = `${mainPeople} - ${title}`
        }

        const details = []

        people.forEach((p) => {
          const value = p.value
            .split(',')
            .filter((v) => v != mainPeople && v.indexOf('<em>') > -1)
          if (value.length) {
            const role = p.key
            details.push(`${role}: ${value.join(', ')}`)
          }
        })
        details.push(...metas)
        this.title = title
        if (details.length) {
          this.subtitles.push(details.join(' - '))
        }
        if (tags.length) {
          this.subtitles.push(tags.map((t) => `#${t.toLowerCase()}`).join(', '))
        }
      },
      immediate: true
    }
  },
  methods: {
    getItemIcon(item) {
      if (item.id === 'remote') return 'storage-cloud'
      if (item.id === 'local') return 'storage-local'
      else return item.isFile ? 'music-box-outline' : 'folder'
    },
    getItemTitle(item) {
      // if (item.id === 'remote') return 'Cloud'
      // else if (item.id === 'local') return 'Local storage'
      return item.meta?.title || item.name
    },
    getItemSubtitle(item) {
      // if (item.id === 'remote') return 'Cloud'
      // else if (item.id === 'local') return 'Local storage'
      // eslint-disable-next-line
      let { title, ...metas } = item.metas || {}
      if (metas) {
        metas = Object.values(item.meta)
      }
      return metas.join(', ')
    },
    getItemTo(item) {
      if (!item.path) return ''
      if (item.isFolder) return `/drive/${item.path}`
      else return `/score/${item.path}`
    }
  }
}
</script>

<style scoped>
.drive-item ::v-deep em {
  position: relative;
  background: #e9810080;
  font-style: initial;
}
</style>
