import { getApp } from '@firebase/app'
import router from '../router'
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  onAuthStateChanged
} from '@firebase/auth'

const appImpl = getApp()
const appAuth = getAuth(appImpl)

const STATUS = {
  initialize: 'initializing',
  connecting: 'connecting',
  disconnected: 'disconnected',
  connected: 'connected'
}

const state = {
  status: STATUS.initialize,
  user: null,
  redirect: null
}

const getters = {
  authStatus: (state) => state.status,
  authConnected: (state) => state.status === STATUS.connected,
  authUser: (state) => state.user || { displayName: 'Guest' },
  authRedirect: (state) => state.redirect
}

const mutations = {
  setStatus(state, value) {
    state.status = value
    console.log('AUTH STATUS : ' + value)
  },
  setUser(state, value) {
    state.user = value
  },
  setRedirect(state, value) {
    state.redirect = value
  }
}

const actions = {
  notify({ dispatch }, type) {
    const details = { store: 'auth', type, user: state.user }
    dispatch('drive/on', details, { root: true })
  },

  // This function is call by main.js to
  initialize: ({ commit, state, dispatch }) => {
    const authRequired = (route) => {
      let required = route.meta.authRequired
      return typeof required === 'function' ? required(route) : required
    }
    //
    // Hook route change to redirect to auth if required
    //
    router.beforeEach((to, from, next) => {
      const userDisconnected = state.status === 'disconnected'
      if (userDisconnected && authRequired(to)) {
        commit('setRedirect', to.path)
        next('/auth')
        return
      }
      next()
    })
    //
    // Add listener for user connection
    //
    onAuthStateChanged(appAuth, async (user) => {
      if (user) {
        const displayName = user.displayName || user.email || 'unknown'
        const names = displayName.split(' ')
        let initials
        if (names[1]) initials = names[0].charAt(0) + names[1].charAt(0)
        else initials = displayName.slice(0, 2)
        initials = initials.toUpperCase()
        commit('setUser', {
          displayName,
          initials,
          email: user.email,
          emailVerified: user.emailVerified,
          photoURL: user.photoURL,
          isAnonymous: user.isAnonymous,
          providerData: user.providerData,
          uid: user.uid
        })
        commit('setStatus', STATUS.connected)
        //
        if (state.redirect) {
          router.replace(state.redirect)
          commit('setRedirect', null)
        }
        await dispatch('notify', 'login')
      } else {
        commit('setUser', null)
        commit('setStatus', STATUS.disconnected)
        //
        if (authRequired(router.currentRoute)) {
          commit('setRedirect', router.currentRoute.path)
          router.push('/auth')
        }
        await dispatch('notify', 'logout')
      }
    })
  },

  authLogin({ commit }, { email, password }) {
    commit('setStatus', STATUS.connecting)
    return signInWithEmailAndPassword(appAuth, email, password).catch(
      (error) => {
        commit('setStatus', STATUS.disconnected)
        throw error
      }
    )
  },

  authGoogleLogin({ commit }) {
    commit('setStatus', STATUS.connecting)
    const provider = new GoogleAuthProvider()
    return signInWithPopup(appAuth, provider).catch((error) => {
      commit('setStatus', STATUS.disconnected)
      throw error
    })
    // .then((result) => {
    //   // This gives you a Google Access Token. You can use it to access the Google API.
    //   const credential = GoogleAuthProvider.credentialFromResult(result)
    //   const token = credential.accessToken
    //   // The signed-in user info.
    //   const user = result.user
    //   // ...
    // })
    // .catch((error) => {
    //   // Handle Errors here.
    //   const errorCode = error.code
    //   const errorMessage = error.message
    //   // The email of the user's account used.
    //   const email = error.email
    //   // The AuthCredential type that was used.
    //   const credential = GoogleAuthProvider.credentialFromError(error)
    //   // ...
    // })
  },

  authLogout() {
    return appAuth.signOut()
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
