import { chordParserFactory, chordRendererFactory } from 'chord-symbol'

const parseChord = chordParserFactory()

const fromRaw = (raw) => ({ raw, chord: parseChord(raw) })

export default class Chord {
  constructor(raw, transpose) {
    const base = fromRaw(raw)
    let current
    if (transpose && !base.chord.error) {
      const renderChord = chordRendererFactory({
        transposeValue: transpose,
        useShortNamings: true
      })
      const transposedRaw = renderChord(base.chord)
      current = fromRaw(transposedRaw)
    }
    this.transpose = transpose
    this.base = base
    this.current = current || base
  }
}
