import { clampVolume } from '../utils/store-utils'
import router from '../router'

const SOUNDS = [
  { key: 'up', module: import('../assets/synth/metronomeup.wav') },
  { key: 'down', module: import('../assets/synth/metronome.wav') },
  { key: 'stick', module: import('../assets/synth/stick.wav') }
]

let Tone
let sampler

const STATUS = { off: 0, starting: 1, started: 2 }
const VOLUME_RATIO = 0.7

const state = {
  status: STATUS.off,
  settings: {
    volume: 0,
    display: 'none'
  }
}

const getters = {
  //
  // Settings
  //
  volume: (state) => state.settings.volume,
  display: (state) => state.settings.display
}

const mutations = {
  setStatus: (state, value) => (state.status = value),
  //
  // Settings
  //
  volume: (state, value) => {
    state.settings.volume = value
    if (sampler) {
      sampler.volume.value = clampVolume(value, VOLUME_RATIO)
    }
  },
  display: (state, value) => (state.settings.display = value)
}

const actions = {
  setSettings({ commit }, { name, value }) {
    commit(name, value)
  },

  // ===========================================================================

  async on({ dispatch }, { report, head, type, playing }) {
    const isMuted = !state.settings.volume
    const contextId = router.currentRoute.params.contextId
    const isEditor = contextId === 'editor'
    const hasNoMetric = !report.hasMetric

    if (isMuted || isEditor || hasNoMetric) return
    if (state.status === STATUS.off) {
      await dispatch('start')
    }
    if (type === 'countdown') {
      sampler.player('stick').start()
    }
    if (playing && (type === 'play' || type === 'progress')) {
      const events = report.events()
      const headEvent = events[head]
      if (headEvent.onFragment) {
        sampler.player('up').start()
      } else if (headEvent.onBeat) {
        sampler.player('down').start()
      }
    }
  },

  // ===========================================================================

  async start({ commit }) {
    if (state.status != STATUS.off) return
    commit('setStatus', STATUS.starting)
    Tone = await import('tone')
    await Tone.start()
    const urls = {}
    for await (let p of SOUNDS) {
      const module = await p.module
      urls[p.key] = module.default
    }
    return new Promise((resolve, reject) => {
      sampler = new Tone.Players({
        urls,
        volume: clampVolume(state.settings.volume, VOLUME_RATIO),
        onload: () => {
          console.log('METRONOME STARTED')
          commit('setStatus', STATUS.started)
          resolve()
        },
        onerror: (error) => {
          reject(error)
        }
      }).toDestination()
    })
  },

  triggerMetronome() {
    sampler.start()
  }
}

export default { namespaced: true, getters, state, mutations, actions }
