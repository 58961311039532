import router from '../router'

const getDefaultSelection = () => ({ length: 0, isEmpty: true })

//
// start : Time (in ms) of the start of the playing
// progress : Time (in ms) since the start date (head)
// delay : Value (in beat) before the playing. Used for countdown.
// head: Current head event index
// selection : Start and length of the selection
// delaying: Flag which indicate there is a countdown running.
//
const state = {
  start: null,
  progress: 0,
  delay: 0,

  head: -1,
  selection: getDefaultSelection(),

  delaying: false,
  loading: false,
  playing: false,

  settings: {
    highlight: 'event'
  }
}

const getters = {
  //
  // Progress
  //
  progress: (state) => state.progress,
  head: (state) => state.head,
  selection: (state) => state.selection,
  //
  // Helpers
  //
  renderer(state, getters, rootState, rootGetters) {
    return rootGetters['score/score']?.settings?.renderer
  },
  report(state, getters, rootState, rootGetters) {
    return rootGetters['score/report']
  },
  tempoRatio(state, getters, rootState, rootGetters) {
    return rootGetters['score/score']?.settings?.tempo || 1
  },
  headEvent(state, getters) {
    const report = getters.report
    if (state.head < 0 || !report) return
    const events = getters.report.events()
    return events[state.head]
  },
  //
  // Playing status
  //
  playing: (state) => state.playing,
  delay: (state) => state.delay,
  delaying: (state) => state.delay,
  loading: (state) => state.loading,
  //
  // Settings
  //
  highlight: (state) => state.settings.highlight
}

const mutations = {
  start(state, value) {
    state.start = value
  },
  progress(state, value) {
    state.progress = value
  },

  head(state, value) {
    state.head = value
  },
  selection(state, value) {
    state.selection = value || getDefaultSelection()
  },

  delay(state, value) {
    state.delay = value
  },
  playing(state, value) {
    state.playing = value
  },
  delaying(state, value) {
    state.delaying = value
  },
  loading: (state, value) => (state.loading = value),
  //
  // Tools
  //
  clear() {
    state.start = null
    state.progress = 0
    state.head = -1
    state.selection = getDefaultSelection()
  },
  destroy() {
    state.start = null
    state.progress = 0
    state.head = -1
    state.selection = getDefaultSelection()
    state.delay = 0
    state.playing = false
    state.delaying = false
  },
  //
  // Settings
  //
  highlight: (state, value) => (state.settings.highlight = value)
}

const actions = {
  setSettings({ commit, dispatch }, { name, value }) {
    if (value != state.settings[value]) {
      commit(name, value)
      if (name === 'highlight') {
        dispatch('updateSelection')
      }
    }
  },

  // ===========================================================================

  /**
   * listener from other stores
   */
  on({ dispatch }, { type, store }) {
    if (type === 'report' && store === 'score') {
      dispatch('updateHead')
      dispatch('updateSelection')
    }
  },

  // ===========================================================================

  /**
   * Send notification to other stores.
   *
   * Type are :
   * - load : when assets are loaded (loadAssets)
   * - play: when player starts playing (play)
   * - progress : when progress change during playing (playloop)
   * - countdown : when countdown change (playWithDelay)
   * - goto : when progress change by a goto (goto)
   * - stop: when playing stop
   */
  async notify({ dispatch, state, getters }, type) {
    const report = getters.report
    const details = { store: 'player', type, report, ...state }

    await dispatch('metronome/on', details, { root: true })
    await dispatch('synth/on', details, { root: true })
    await dispatch('cast/on', details, { root: true })
  },

  // ===========================================================================

  async loadAssets({ commit, dispatch }) {
    commit('loading', true)
    await dispatch('notify', 'load')
    commit('loading', false)
  },

  // ===========================================================================

  /**
   * Entry function to start playing
   */
  async play({ state, commit, dispatch, getters }) {
    if (state.playing) return
    const events = getters.report.events()
    const isEnd = events.length - 1 === getters.head
    const tempoRatio = getters.tempoRatio
    if (isEnd) {
      await dispatch('goto', { progress: 0 })
    } else if (state.selection.start) {
      const eventStart = events[state.selection.start]
      commit('progress', Math.round(eventStart.position.time / tempoRatio))
    }
    let progress = state.progress
    //
    // First update
    //
    if (getters.head < 0) {
      await dispatch('updateHead')
      await dispatch('updateSelection')
    }
    await dispatch('loadAssets')

    commit('start', new Date(Date.now() - progress))
    commit('playing', true)

    await dispatch('notify', 'play')

    window.requestAnimationFrame(() => dispatch('playLoop'))
  },

  // ===========================================================================

  updateFromPeer({ commit, dispatch }, { start, progress, playing, delay }) {
    commit('delay', delay)
    commit('start', start ? new Date(start) : null)
    commit('playing', playing)
    commit('progress', progress)
    commit('delaying', delay > 0)
    dispatch('updateHead')
    dispatch('updateSelection')
  },

  // ===========================================================================

  /**
   * Function exectuted each loop of the playing
   */
  async playLoop({ state, dispatch, commit, getters }) {
    if (state.playing) {
      const report = getters.report
      if (!report) {
        dispatch('stop')
        return
      }
      const tempoRatio = getters.tempoRatio
      const max = Math.round(report.duration.time / tempoRatio)
      const progress = Math.min(max, Date.now() - state.start.getTime())
      const diff = progress - state.progress
      if (diff > 1 || progress === max) {
        commit('progress', progress)
        const currentHead = state.head
        await dispatch('updateHead')
        await dispatch('updateSelection')
        if (currentHead != state.head) {
          dispatch('notify', 'progress')
        }
      }
      if (progress < max) {
        window.requestAnimationFrame(() => dispatch('playLoop'))
      } else {
        dispatch('stop')
      }
    }
  },

  // ===========================================================================

  async playWithDelay({ commit, dispatch, getters, state }, countdown) {
    if (state.delay) return
    if (getters.head < 0) {
      await dispatch('updateHead')
      await dispatch('updateSelection')
    }

    await dispatch('loadAssets')

    const headEvent = getters.headEvent
    const bps = headEvent.tempo / 60
    const beatDuration = 1000 / bps
    const startAt = Date.now()

    commit('delay', countdown)
    commit('delaying', true)
    dispatch('notify', 'countdown')

    //
    // Interval function to update the delay
    //
    const loop = function () {
      if (!state.delaying) return
      const lastDelay = state.delay
      const elapsedTime = Date.now() - startAt
      const elapsedBeat = elapsedTime / beatDuration
      const newCountdown = Math.max(0, countdown - elapsedBeat)
      commit('delay', Math.ceil(newCountdown))
      if (newCountdown > 0) {
        if (lastDelay != state.delay) {
          dispatch('notify', 'countdown')
        }
        window.requestAnimationFrame(loop)
      } else {
        commit('delaying', false)
        dispatch('play')
      }
    }
    loop()
  },

  // ===========================================================================

  async goto({ commit, dispatch }, { progress, noUpdate }) {
    commit('progress', Math.round(progress))
    commit('start', new Date(Date.now() - progress))
    commit('delay', 0)
    commit('delaying', false)
    if (!noUpdate) {
      await dispatch('updateHead')
      await dispatch('updateSelection')
    }
    dispatch('notify', 'goto')
  },

  // ===========================================================================

  async stop({ commit, dispatch }) {
    if (state.playing) {
      commit('playing', false)
      commit('delay', 0)
      commit('delaying', false)
      await dispatch('notify', 'stop')
    }
  },

  // ===========================================================================

  updateHead({ state, commit, getters }) {
    const report = getters.report
    const tempoRatio = getters.tempoRatio
    if (!report.events) {
      console.warn('No report available. Update cannot be done.')
      return
    }
    const events = report.events && report.events()
    //
    // Ensure we have content, if not we clear
    //
    if (!events.length) {
      commit('clear')
      return
    }
    //
    // Get the current Event index. We loop through events from the current
    // event to the last event and then from the first to the current.
    //
    const eventCount = events.length
    let head, event
    for (var i = 0; i < eventCount; i++) {
      const currentHead = state.head > 0 ? state.head : 0
      head = i + currentHead
      if (head >= eventCount) {
        head -= eventCount
      }
      event = events[head]
      const start = Math.round(event.position.time / tempoRatio)
      const duration = Math.round(event.duration.time / tempoRatio)
      const end = start + duration
      const isAfter = start <= state.progress
      const isBefore = state.progress < end || head === eventCount - 1
      if (isBefore && isAfter) {
        break
      }
    }
    if (head != state.head) {
      commit('head', head)
    }
  },

  updateSelection({ state, getters, commit }) {
    const report = getters.report
    const headEvent = getters.headEvent
    if (!headEvent) {
      commit('selection')
      return
    }
    const fragment = headEvent.fragment
    const selection = { measureIndex: fragment.index }
    const contextId = router.currentRoute.params.contextId
    const isEditor = contextId === 'editor'
    let highlight = state.settings.highlight
    if (isEditor && highlight != 'none') {
      highlight = 'event'
    }
    if (report && highlight != 'none' && getters.renderer === 'lyric') {
      highlight = 'line'
    }
    selection.highlight = highlight
    switch (highlight) {
      case 'line':
        //
        // We cannot know what is a line with fragment info. That must be
        // evaluated by the renderer
        //
        selection.isLine = true
        selection.start = selection.end = headEvent.index
        break
      case 'measure': {
        const lastBeat = fragment[fragment.length - 1]
        selection.isMeasure = true
        selection.beats = Array(fragment.beatCount).map((a, i) => i)
        selection.start = fragment[0][0].index
        selection.end = lastBeat[lastBeat.length - 1].index
        break
      }
      case 'beat': {
        const currentBeat = fragment[headEvent.beatIndexInFragment]
        selection.isBeat = true
        selection.beats = [headEvent.beatIndexInFragment]
        selection.start = currentBeat[0].index
        selection.end = currentBeat[currentBeat.length - 1].index
        break
      }
      case 'event':
        selection.isEvent = true
        selection.start = selection.end = headEvent.index
        break
      case 'none':
        selection.isNone = true
        selection.start = selection.end = headEvent.index
    }
    if ('start' in selection) {
      selection.length = selection.end - selection.start + 1
      if (selection.length < 0 || isNaN(selection.length)) {
        throw new Error('WTF : length should no be negative')
      }
      commit('selection', selection)
    } else {
      commit('selection')
    }
  }
}

export default { namespaced: true, getters, state, mutations, actions }
