<template>
  <common-dialog
    ref="dialog"
    :trigger="proceed"
    :disabled="!valid || !email || !name || !message"
    title="Contact"
    width="600"
    notif="Your message has been sent"
  >
    <v-form ref="form" lazy-validation v-model="valid">
      <v-text-field
        label="Name"
        v-model="name"
        :rules="nameRules"
        placeholder=" "
        persistent-placeholder
      />
      <v-text-field
        label="Email"
        v-model="email"
        :rules="emailRules"
        placeholder=" "
        persistent-placeholder
      />
      <v-textarea
        label="Message"
        v-model="message"
        placeholder=" "
        :rules="messageRules"
        persistent-placeholder
        height="100%"
        no-resize
      />
    </v-form>
  </common-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import CommonDialog from './CommonDialog.vue'

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
  name: 'ContactDialog',
  data: () => ({
    valid: false,
    name: null,
    nameRules: [(v) => !!v || 'What is your name?'],
    email: null,
    emailRules: [
      (v) => !!v || 'How do I reply to you?',
      (v) => EMAIL_REGEX.test(v) || 'This address is not correct'
    ],
    message: null,
    messageRules: [(v) => !!v || 'What do you want to say?']
  }),
  methods: {
    ...mapActions('contact', ['contactSend']),

    async open() {
      await this.$refs.dialog.open()
      this.$refs.form.reset()
      // this.name = 'Juju'
      // this.email = 'julien@quarkstudio.fr'
      // this.message = 'Hi there'
    },

    async proceed() {
      return this.contactSend({
        name: this.name,
        email: this.email,
        message: this.message
      })
      // const email = this.email
      // const password = this.password
      // await this.authLogin({ email, password })
    }
  },

  components: {
    CommonDialog
  }
}
</script>

<style scoped>
.v-form > *:not(:last-child) {
  /* margin-bottom: var(--app-gutter-md); */
}
</style>
