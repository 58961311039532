export const timeout = (duration) =>
  new Promise((resolve) => setTimeout(resolve, duration))

//
// This allow to generate a id of 20 digits as firebase do
//
// https://gist.github.com/mikelehen/3596a30bd69384624c11
//
var PUSH_CHARS =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
var lastPushTime = 0
var lastRandChars = []
export const generateID = () => {
  var now = new Date().getTime()
  var duplicateTime = now === lastPushTime
  lastPushTime = now
  const charsLength = PUSH_CHARS.length
  var timeStampChars = new Array(8)
  for (var i = 7; i >= 0; i--) {
    timeStampChars[i] = PUSH_CHARS.charAt(now % charsLength)
    now = Math.floor(now / charsLength)
  }
  if (now !== 0)
    throw new Error('We should have converted the entire timestamp.')

  var id = timeStampChars.join('')

  if (!duplicateTime) {
    for (i = 0; i < 12; i++) {
      lastRandChars[i] = Math.floor(Math.random() * charsLength)
    }
  } else {
    // If the timestamp hasn't changed since last push, use the same random
    // number, except incremented by 1.
    for (i = 11; i >= 0 && lastRandChars[i] === charsLength - 1; i--) {
      lastRandChars[i] = 0
    }
    lastRandChars[i]++
  }
  for (i = 0; i < 12; i++) {
    id += PUSH_CHARS.charAt(lastRandChars[i])
  }
  if (id.length !== 20) throw new Error('Length should be 20.')

  return id
}

export const clampVolume = (percent, ratio) => {
  const r = Math.sqrt(percent / 100) * (ratio || 1)
  const min = -30
  const max = 6
  return min + (max - min) * r
}
