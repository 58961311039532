import Vue from 'vue'
const state = {
  editor: null,
  dialogs: {},
  settings: {
    preview: false
  }
}

const getters = {
  editor() {
    return (state.editor && state.editor()) || null
  },
  dialogs: () => (name) => {
    const dialog = state.dialogs[name]
    return (dialog && dialog()) || null
  },
  isSplitted: (state) => state.editor && state.settings.preview,
  //
  // Settings
  //
  preview: (state) => state.settings.preview
}

const mutations = {
  setEditor(state, value) {
    state.editor = () => value
  },
  setDialog(state, { name, value }) {
    state.dialogs[name] = () => value
  },
  //
  // Settings
  //
  preview: (state, value) => (state.settings.preview = value)
}

const actions = {
  setSettings({ commit }, { name, value }) {
    commit(name, value)
  },

  insertChar({ getters }, char) {
    const editor = getters.editor
    const doc = editor.getDoc()
    doc.replaceRange(char, doc.getCursor(true), doc.getCursor(false))
    editor.focus()
  },

  insert({ getters }, { destination, content }) {
    const editor = getters.editor
    const doc = editor.getDoc()
    const start = doc.getCursor(true)
    const end = doc.getCursor(false)
    const endLine = doc.getLine(end.line)
    const endIsLast = end.ch === endLine.length
    switch (destination) {
      case 'replace':
        doc.replaceSelection(content, 'around')
        break
      case 'before':
        if (start.ch === 0) content += '\r'
        doc.replaceRange(content, start)
        break
      case 'after':
        if (endIsLast) content = '\r' + content
        doc.replaceRange(content, end)
        doc.setSelection(
          {
            line: endIsLast ? end.line + 1 : end.line,
            ch: endIsLast ? 0 : end.ch
          },
          doc.getCursor(false)
        )
        break
    }
    editor.focus()
  },

  undo({ getters }) {
    const editor = getters.editor
    editor.undo()
    editor.focus()
  },

  redo({ getters }) {
    const editor = getters.editor
    editor.redo()
    editor.focus()
  },

  copy({ getters }) {
    const editor = getters.editor
    navigator.clipboard.writeText(editor.getSelection())
  },

  async paste({ getters }) {
    const editor = getters.editor
    const doc = editor.getDoc()
    const text = await navigator.clipboard.readText()
    doc.replaceRange(text, doc.getCursor(true), doc.getCursor(false))
    editor.focus()
  },

  setSelection({ getters }, { from, to }) {
    const editor = getters.editor
    const doc = editor.getDoc()
    doc.setSelection(from, to)
    editor.focus()
  },

  setSelectionFromRawPos({ getters }, rawPos) {
    const editor = getters.editor
    const doc = editor.getDoc()
    const from = { line: rawPos.line, ch: rawPos.pos }
    const to = {
      line: rawPos.line,
      ch: rawPos.pos + rawPos.length
    }
    doc.setSelection(to, from)
    editor.focus()
  },

  togglePreview({ dispatch }) {
    dispatch('app/setSettings', ['editor/preview', !state.settings.preview], {
      root: true
    })

    Vue.nextTick(() => {
      window.dispatchEvent(new Event('resize'))
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
