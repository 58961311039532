<template>
  <div>
    <v-dialog
      v-model="isOpened"
      :width="dialogWidth"
      max-width="90vw"
      content-class="dialog-content"
      :persistent="!noAction"
      :retain-focus="retainFocus"
      class="dialog"
    >
      <v-card :style="`height:${cardHeight}`">
        <v-card-title>
          <span v-if="title" class="headline pb-2">{{ title }}</span>
          <v-spacer />
          <slot name="header" />
        </v-card-title>
        <v-card-text>
          <v-alert type="error" v-if="error">
            <strong>{{ error.name }}</strong> : {{ error.message }}
          </v-alert>
          <v-alert :type="messageType || 'info'" v-else-if="message">
            {{ message }}
          </v-alert>
          <slot></slot>
        </v-card-text>
        <v-card-actions v-if="!noAction">
          <v-spacer></v-spacer>
          <v-btn v-if="cancel" text @click="close()" :disabled="isProcessing">
            {{ cancel }}
          </v-btn>
          <v-btn
            v-if="ok"
            color="accent"
            :loading="isProcessing"
            @click="proceed()"
            :disabled="disabled"
            >{{ ok }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { timeout } from '../../../../jelly-sheet/src/utils/tools-utils'

export default {
  name: 'CommonDialog',
  props: {
    title: String,
    message: String,
    messageType: String,
    cancel: { type: String, default: 'Cancel' },
    ok: { type: String, default: 'Ok' },
    args: undefined,
    trigger: Function,
    disabled: Boolean,
    notif: String,
    fullscreen: Boolean,
    width: String,
    noAction: Boolean,
    autoclose: { type: Boolean, default: true },
    retainFocus: Boolean
  },
  data: () => ({
    isOpened: false,
    error: null,
    isProcessing: false
  }),
  computed: {
    dialogWidth() {
      return this.fullscreen ? '90vw' : this.width || '600px'
    },

    cardHeight() {
      return this.fullscreen ? '90vh' : 'initial'
    }
  },
  methods: {
    ...mapMutations('app', ['setNotification']),

    async open() {
      this.error = null
      this.isOpened = true
      this.complete = false
      await timeout(5)
    },
    async proceed() {
      this.isProcessing = true
      await this.trigger(this.args)
        .then(() => {
          this.complete = true
          if (this.notif) {
            this.setNotification({
              label: this.notif,
              color: 'success'
            })
          }
          if (this.autoclose) this.close()
        })
        .catch((error) => {
          console.error(error)
          this.error = error
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    close: function () {
      this.isOpened = false
    }
  }
}
</script>
<style scoped>
/* .v-dialog {
  height: 90vh;
} */
.v-card {
  /* min-width: 30vw; */
  background-color: var(--app-color-paper) !important;
  display: flex;
  flex-direction: column;
}
.v-card__title {
  align-items: baseline;
}
.v-card__text {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: calc(90vh - 52px - 66px);
  overflow: auto;
}
</style>
