import set from 'lodash/set'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import * as PIXI from 'pixi.js'

import { Settings } from '../../../jelly-flux/src/class'

const PAPER_COLOR = 'FFFFFF'
const LINE_COLOR = '999999'
const METRIC_COLOR = '666666'
const PART_COLOR = 'bbbbbb'
const EMPTY_PART_COLOR = 'eeeeee'
const CHORD_BACKGROUND_COLOR = 'efefef'
const SELECTION_COLOR = 'e98100'

const LABEL_DARK_COLOR = '000000'
const LABEL_LIGHT_COLOR = 'FFFFFF'

const toPX = (v) => `${v}px`
const toCssColor = (v) => `#${v}`
const toHexa = (v) => Number(`0x${v}`)

export const regexSettings = {
  note: /([abcdefg*r])([#b!])?([↑↓]+|[0-8])?/
  // lyric: /([«»êéèëàùôa-zA-Z!?,;'":.\s]+)(~|-|_)?/
}

export const manifest = [
  { name: 'tune', symbol: '#', regex: /([abcdefg])([#b])?/ },
  { name: 'tempo', symbol: '!', regex: /\d+/ },
  { name: 'part', symbol: '§' },
  { name: 'chord', symbol: 'H', breakline: true },
  { name: 'note', symbol: 'M', breakline: true, regex: regexSettings.note },
  { name: 'lyric', symbol: 'L', breakline: true }
]

export default class SheetSettings extends Settings {
  constructor() {
    super()
    //
    // USER
    //
    this.user = {
      //
      // User global
      //
      renderer: 'lyric',
      renderOptions: ['lyric', 'harmony', 'part', 'metric'],
      //
      // User score
      //
      layout: 'wrap',
      spacing: 'default',
      transpose: 0,
      tempo: 1,
      highlight: 'none',
      //
      //
      //
      selectMode: 'event'
    }
    //
    // MANIFEST
    //
    this.manifest.set(manifest)
    //
    // All renderer
    //
    this.sheet = {
      margin: 24,
      padding: 36
    }
    this.sheet.css = {
      'background-color': toCssColor(PAPER_COLOR),
      'selection-color': toCssColor(SELECTION_COLOR),
      'min-width': toPX(300),
      'min-height': toPX(200),
      margin: toPX(this.sheet.margin),
      padding: toPX(this.sheet.padding),
      'box-shadow':
        '00px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',

      'chord-background-color': toCssColor(CHORD_BACKGROUND_COLOR),
      'part-color': toCssColor(PART_COLOR)
    }

    //
    // LYRIC RENDERER
    //
    this.lyric = {
      css: {
        'chord-font-family': 'Palanquin',
        'chord-weight': 500,
        'chord-font-size': toPX(15),

        'lyric-font-family': 'DM Sans',
        'lyric-weight': 400,
        'lyric-font-size': toPX(15),

        'part-font-family': 'Roboto',
        'part-weight': 400,
        'part-font-size': toPX(11),
        'part-opacity': 0.5,
        'part-gutter': toPX(20),

        'bloc-gutter': toPX(5),
        'bloc-spacer': toPX(4),
        'line-gutter': toPX(6),
        'size-measure': toPX(150),
        'color-metric': toCssColor(METRIC_COLOR)
      }
    }
    //
    // CHART RENDERER ==========================================================
    //

    this.chart = {
      measureWidth: 180
    }
    this.chart.css = {
      'chord-font-family': 'Palanquin',
      'chord-weight': 700,
      'chord-font-size': toPX(17),

      'part-font-family': 'Roboto',
      'part-weight': 400,
      'part-font-size': toPX(11),
      'part-opacity': 0.5,
      'part-gutter': toPX(20),

      'bloc-spacer': toPX(4),
      'measure-width': toPX(this.chart.measureWidth),
      'measure-height': toPX(120),
      'color-line': toCssColor(LINE_COLOR)
    }

    //
    // STAFF RENDERER ==========================================================
    //

    const staffLabel = {}
    staffLabel.chord = {
      font: '500 16px "Palanquin"',
      fontFamily: 'Palanquin',
      fontWeight: 500,
      fontSize: '16px',
      fill: toHexa(LABEL_DARK_COLOR)
    }
    staffLabel.lyric = {
      font: '400 14px "DM Sans"',
      fontFamily: 'DM Sans',
      fontWeight: 400,
      fontSize: '14px',
      fill: toHexa(LABEL_DARK_COLOR)
    }
    staffLabel.part = {
      font: '400 11px "Roboto"',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '11px',
      fill: toHexa(PART_COLOR)
    }

    staffLabel.chordLight = Object.assign({}, staffLabel.chord, {
      fill: toHexa(LABEL_LIGHT_COLOR)
    })
    staffLabel.lyricLight = Object.assign({}, staffLabel.lyric, {
      fill: toHexa(LABEL_LIGHT_COLOR)
    })
    staffLabel.partLight = Object.assign({}, staffLabel.part, {
      fill: toHexa(LABEL_LIGHT_COLOR)
    })
    staffLabel.chordSelection = Object.assign({}, staffLabel.chord, {
      fill: toHexa(SELECTION_COLOR)
    })
    staffLabel.lyricSelection = Object.assign({}, staffLabel.lyric, {
      fill: toHexa(SELECTION_COLOR)
    })

    staffLabel.chord = new PIXI.TextStyle(staffLabel.chord)
    staffLabel.lyric = new PIXI.TextStyle(staffLabel.lyric)
    staffLabel.part = new PIXI.TextStyle(staffLabel.part)

    staffLabel.chordLight = new PIXI.TextStyle(staffLabel.chordLight)
    staffLabel.lyricLight = new PIXI.TextStyle(staffLabel.lyricLight)
    staffLabel.partLight = new PIXI.TextStyle(staffLabel.partLight)

    staffLabel.chordSelection = new PIXI.TextStyle(staffLabel.chordSelection)
    staffLabel.lyricSelection = new PIXI.TextStyle(staffLabel.lyricSelection)

    this.staff = {
      background: { color: toHexa(PAPER_COLOR), css: toCssColor(PAPER_COLOR) },
      selection: toHexa(SELECTION_COLOR),
      label: staffLabel,
      anchor: {
        bottom: 4,
        gutter: 2,
        articulation: 4,
        thickness: 1
      },
      staff: {
        unit: 8,
        line: { count: 5, height: 1 },
        before: { padding: { left: 4, right: 10 }, gap: 5 }
      },
      extra: { overflow: 2, thickness: 1 },
      dot: { gutter: 4, radius: 1.5, spacer: 1 },
      accidental: { gutter: 2 },
      flag: { offset: 10 },
      stem: { thickness: 1, height: 28, minHeight: 24, offset: 2 },
      beam: { thickness: 4, gap: 2, maxHeight: 8, offset: 2 },
      tuplet: {
        thickness: 8,
        textWidth: 7,
        textHeight: 13,
        textPadding: 4,
        beamPadding: 4
      },
      bar: {
        offset: 10
      },
      tie: {
        height: 5,
        thickness: 2,
        before: 20,
        handle: 0.1
      },
      keySignature: {
        offsetX: 7,
        offsetY: 4
      },
      measure: {
        beatWidth: 25
      },
      row: { gutter: 20 },
      lyric: { gutter: 4, anchor: 10, offset: 8, offsetText: 1 },
      bound: {
        padding: 4,
        alpha: { over: 1, default: 0 },
        color: toHexa(SELECTION_COLOR)
      },
      note: { minWidth: 15, minLastWidth: 20, padding: 4 },
      chord: {
        color: toHexa(CHORD_BACKGROUND_COLOR),
        offsetText: 0,
        marginBottom: 8
      },
      part: {
        paddingHorizontal: 4,
        paddingTop: 4,
        paddingBottom: 20,
        emptyPaddingTop: -4,
        emptyPaddingBottom: 16,
        thickness: 1,
        color: toHexa(PART_COLOR),
        emptyColor: toHexa(EMPTY_PART_COLOR),
        offsetText: 0
      }
    }
  }
  set(path, value) {
    const current = get(this, path)
    if (!isEqual(current, value)) {
      set(this, path, value)
      return true
    }
  }
}
