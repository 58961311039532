<template>
  <v-navigation-drawer
    class="drawer"
    app
    right
    clipped
    :value="showDrawer"
    disable-resize-watcher
  >
    <score-drawer v-if="showDrawer"></score-drawer>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

import ScoreDrawer from './ScoreDrawer.vue'

export default {
  name: 'rightDrawer',
  components: { ScoreDrawer },
  computed: {
    ...mapGetters('app', ['settings']),
    ...mapGetters('editor', ['preview']),

    showDrawer() {
      const settings = this.settings.app.drawers[1]
      if (!settings) return false
      const isEditor = this.$route.params.contextId === 'editor'
      if (isEditor) {
        return this.preview
      } else {
        return this.$route.meta?.hasDrawer
      }
    }
  }
}
</script>
<style scoped>
.drawer {
  background: var(--app-color-layout) !important;
}
.drawer-menu {
  display: flex;
  border-bottom: var(--app-color-line) solid 1px;
  align-items: center;
  justify-content: flex-end;
  padding-right: var(--app-gutter-xs);
}

.drawer-menu .v-btn {
  height: 48px;
  width: 48px;
}
</style>
