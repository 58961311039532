// import { timeout } from '../utils/store-utils'
// import { openDb, dbSet, dbGet, dbAdd, dbGetAll } from '../utils/db-utils'

import cloneDeep from 'lodash/cloneDeep'

// let settingsMap

const state = {
  initialized: false,
  notification: null,
  contextMenu: null,
  headerHidden: false,
  settings: {
    menu: false,
    darkmode: false,
    drawers: [false, false]
  }
}

const getters = {
  initialized(state) {
    return state.initialized
  },
  notification(state) {
    return state.notification
  },
  /**
   * Return all settings of all modules. That is usefull to avoid multiple
   * getters in view with the same name.
   */
  settings(state, getters, rootState) {
    const result = {}
    for (const rootKey in rootState) {
      const local = rootState[rootKey]
      result[rootKey] = {}
      const settings = local.settings
      if (settings) {
        result[rootKey] = cloneDeep(settings)
      }
    }
    return result
  },
  headerHidden: (state) => state.headerHidden,
  //
  // Settings
  //
  menu: (state) => state.settings.menu,
  darkmode: (state) => state.settings.darkmode,
  drawers: (state) => state.settings.drawers
}

const mutations = {
  setInitialized: (state, value) => {
    state.initialized = value
  },
  setNotification(state, value) {
    state.notification = value
  },
  setContextMenu(state, value) {
    if (state.contextMenu && value != state.contextMenu) {
      state.contextMenu()
    }
    state.contextMenu = value
  },
  setHeaderHidden(state, value) {
    state.headerHidden = value
  },
  //
  // Settings
  //
  menu: (state, value) => (state.settings.menu = value),
  darkmode: (state, value) => (state.settings.darkmode = value),
  drawers: (state, value) => (state.settings.drawers = value)
}

const actions = {
  db({ dispatch }, payload) {
    const { action, ...args } = payload
    return dispatch('driveLocal/' + action, args, { root: true })
  },

  initialize: async ({ commit, rootGetters, dispatch }) => {
    //
    // Open local database
    //
    // await openDb()
    await dispatch('db', { action: 'openDb' })
    //
    // Get all settings
    //
    // const allSettings = await dbGetAll('settings')
    const allSettings = await dispatch('db', {
      action: 'dbGetAll',
      storeName: 'settings'
    })
    for (const settings of allSettings) {
      for (const name in settings) {
        if (name !== 'id') {
          const path = `${settings.id}/${name}`
          const exists = Object.prototype.hasOwnProperty.call(rootGetters, path)
          if (exists) {
            const value = settings[name]
            commit(path, value, { root: true })
          }
        }
      }
    }
    console.log('APP INITIALIZED')
    commit('setInitialized', true)
  },

  /**
   * Entry point for all settings modification. This actions allow to commit the
   * value (on the required module) and write the modification to the database.
   * That require for each module to have a 'setSettings' action.
   * If the 'index' is given, that's suppose the settings value is an array.
   */
  async setSettings({ dispatch, commit, rootGetters }, [path, value, index]) {
    const exists = Object.prototype.hasOwnProperty.call(rootGetters, path)
    if (!exists) {
      console.error(`Unknown getter '${path}'`)
      return
    }
    //
    // Get the old value
    //
    const oldGetter = this.getters[path]
    let oldValue = oldGetter
    if (!isNaN(index)) {
      oldValue = oldValue[index]
    }
    //
    // Check if the old value has changed
    //
    if (oldValue === value) {
      return
    }
    //
    // If value is array, we clone it, set it (at the index) and use it as value
    //
    if (!isNaN(index)) {
      const array = oldGetter.slice()
      array[index] = value
      value = array
    }
    //
    // Commit the value at the path (can be root). That is done by calling the
    // 'setSettings' action of the module. Each module will make it's own commit
    //
    const paths = path.split('/')
    const moduleName = paths[0]
    const settingName = paths[1]
    const set = async (value) => {
      if (moduleName === 'app') {
        commit(settingName, value)
      } else {
        const path = `${moduleName}/setSettings`
        await dispatch(path, { name: settingName, value }, { root: true })
      }
    }
    await set(value)
    //
    // Get the new value (as well some treatment can be done during mutation)
    //
    const newValue = rootGetters[path]
    //
    // Store the value to the database (remote or local)
    //
    try {
      // const current = await dbGet('settings', moduleName)
      const current = await dispatch('db', {
        action: 'dbGet',
        storeName: 'settings',
        key: moduleName
      })
      if (!current) {
        const obj = { id: moduleName, [settingName]: newValue }
        // await dbAdd('settings', obj)
        await dispatch('db', {
          action: 'dbAdd',
          storeName: 'settings',
          obj
        })
      } else {
        // await dbSet('settings', moduleName, settingName, newValue)
        await dispatch('db', {
          action: 'dbSet',
          storeName: 'settings',
          key: moduleName,
          property: settingName,
          value: newValue
        })
      }
    } catch (e) {
      //
      // In case of problem, we reset the value
      //
      console.error(`Unable to set settings '${path}'' : ${e.error.message}`)
      await set(oldGetter)
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
