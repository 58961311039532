<template>
  <div class="score-tags">
    <span
      :class="`tag tag-${tag}`"
      v-for="(tag, tagIndex) in tags"
      :key="tagIndex"
    >
      {{ getTagLabel(tag) }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'ScoreDrawer',
  props: { tags: Array },

  computed: {},
  methods: {
    getTagLabel(tag) {
      return tag === 'metric' ? '•' : tag.charAt(0)
    }
  }
}
</script>

<style scoped>
.score-tags {
  display: grid;
  gap: var(--app-gutter-xs);
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  font-size: x-small;
  text-transform: uppercase;
  --score-tag-padding-y: var(--app-gutter-xs);
  --score-tag-padding-x: var(--app-gutter-xs);
}
.tag {
  padding: var(--app-gutter-xs);
  background: var(--app-color-paper);
  border-radius: 2px;
  padding: calc(var(--score-tag-padding-y) / 2);
  padding-left: var(--score-tag-padding-x);
  padding-right: var(--score-tag-padding-x);
  background: lightgrey;
  opacity: 0.7;
}

.tag-harmony,
.tag-chord {
  background: hsl(205deg 50% 60%);
}
.tag-melody,
.tag-note {
  background: hsl(324deg 50% 60%);
}
.tag-lyric {
  background: hsl(147deg 50% 60%);
}
</style>
